<template>
  <v-row no-gutters wrap>
    <v-col md="4">
      <v-select
        v-model="type"
        :items="[
          { text: 'Name', value: 'name' },
          { text: 'Mobile', value: 'mobile' },
          { text: 'Email', value: 'email' },
        ]"
        label="Search by"
        outlined
        dense
        :menu-props="{ bottom: true, offsetY: true }"
        background-color="#fff"
        class="text_field1"
      >
      </v-select>
    </v-col>
    <v-col md="8">
      <v-autocomplete
        :value="value"
        :items="entries"
        :loading="isSearchLoading"
        :search-input.sync="search"
        :item-text="type"
        :item-value="type"
        label="Search*"
        placeholder="Search.."
        @change="changeCustomerData"
        auto-select-first
        validate-on-blur
        return-object
        outlined
        dense
        autocomplete="cc-mob"
        class="text_field2"
        background-color="#fff"
      >
        <template v-slot:item="{ item }">
          <v-list-item-avatar
            v-if="item.profile_image"
            rounded
            color="teal"
            class="text-h5 font-weight-light white--text"
          >
            <view-image
              :image="item.profile_image"
              :contain="false"
            ></view-image>
          </v-list-item-avatar>
          <v-list-item-avatar
            v-else-if="item.first_name"
            rounded
            color="teal"
            class="text-h5 font-weight-light white--text"
          >
            {{ item.first_name.charAt(0) }}
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle v-if="type == 'email'">{{
              item.email
            }}</v-list-item-subtitle>
            <v-list-item-subtitle v-else>{{
              item.mobile
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: { type: Object },
    people: { type: Number },
    packageType: { type: String },
    duration: { type: Number },
    trainerBookingId: { type: Number },
    venueServiceId: { type: Number },
  },
  data() {
    return {
      isSearchLoading: false,
      entries: [],
      search: null,
      type: "name",
    };
  },
  watch: {
    search(val) {
      this.searchHelper(val);
    },
  },
  methods: {
    searchHelper(val) {
      if (!val) return;
      if (
        this.value &&
        (this.value.mobile == val ||
          this.value.email == val ||
          this.value.name == val)
      )
        return;
      if (this.isSearchLoading) return;

      this.search = val;
      this.searchCustomer(val);
    },
    searchCustomer(search) {
      this.isSearchLoading = true;
      this.$http
        .get(
          `venues/trainers/customers/search?trainer_booking_id=${this.trainerBookingId}&venue_service_id=${this.venueServiceId}&package_type=${this.packageType}&duration=${this.duration}&people=${this.people}&field=${this.type}&search=${search}`
        )
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            this.isSearchLoading = false;
            this.entries = data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    changeCustomerData(data) {
      this.$emit("input", data);
    },
  },
};
</script>

<style>
</style>