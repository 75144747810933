<template>
  <div>
    <v-sheet tile height="54" class="d-flex">
      <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title class="ma-4" v-if="$refs.calendar">
        {{ $refs.calendar.title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-select
        v-model="type"
        :items="types"
        item-value="value"
        item-text="text"
        dense
        outlined
        hide-details
        class="ma-2 q-autocomplete shadow-0"

        label="Type"

      ></v-select>

      <v-select
        v-model="weekday"
        :items="weekdays"
        dense
        outlined
        hide-details
        label="Weekdays"
        class="ma-2 q-autocomplete shadow-0"

      ></v-select>

      <v-btn icon class="ma-2" @click="$refs.calendar.next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        color="#d5e6eb"
        category-show-all
        :categories="categories"
        v-model="value"
        :weekdays="weekday"
        :type="type == 'day' ? 'category' : type"
        :events="schedules"
        :event-overlap-mode="mode"
        :event-overlap-threshold="60"
        :event-color="getEventColor"
        @change="getTrainerSchedules"
        @click:event="showEvent"
      >
        <template v-slot:day-body="{ date, week }">
          <div
            class="v-current-time"
            :class="{ first: date === week[0].date }"
            :style="{ top: nowY }"
          ></div>
        </template>
        <template v-slot:event="{ event }">
          <div class="pl-2">{{ event.type_name }} - {{ event.name }}</div>
        </template>
      </v-calendar>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      >
        <v-card color="grey lighten-4" min-width="350px" flat class="pt-0">
          <v-toolbar :color="selectedEvent.color" dark>
            <v-toolbar-title v-html="selectedEvent.type"></v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text class="pt-0">
            <v-row
              class="mt-0"
              v-if="selectedEvent.type != 'Trainer Not Available'"
            >
              <v-col md="5">
                <span class="details_subtitle"
                  >{{
                    selectedEvent.type == "Trainer"
                      ? "Package "
                      : selectedEvent.type == "Academy"
                      ? "Academy Name"
                      : ""
                  }}
                </span>
              </v-col>
              <v-col md="7">
                <span class="details_data">
                  {{ selectedEvent.type_name }}
                </span>
              </v-col>
            </v-row>
            <v-row
              class="mt-0"
              v-if="selectedEvent.type != 'Trainer Not Available'"
            >
              <v-col md="5">
                <span class="details_subtitle"
                  >{{
                    selectedEvent.type == "Trainer"
                      ? "Customer "
                      : selectedEvent.type == "Academy"
                      ? "Program "
                      : ""
                  }}
                </span>
              </v-col>
              <v-col md="7">
                <span class="details_data">
                  {{ selectedEvent.name }}
                </span>
              </v-col>
            </v-row>
            <v-row
              class="mt-0"
              v-if="selectedEvent.type != 'Trainer Not Available'"
            >
              <v-col md="5">
                <span class="details_subtitle">Facility </span>
              </v-col>
              <v-col md="7">
                <span class="details_data">
                  {{ selectedEvent.facility_name || "NA" }}
                </span>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col md="5">
                <span class="details_subtitle">Time </span>
              </v-col>
              <v-col md="7">
                <span class="details_data">
                  {{ selectedEvent.start_time | timeFormat }} to
                  {{ selectedEvent.end_time | timeFormat }}
                </span>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col md="12" v-if="selectedEvent.type == 'Academy'">
                <v-btn
                  class="ma-2 white--text teal-color text-center"
                  normal
                  @click="viewStudents(selectedEvent)"
                >
                  <span>View Students</span>
                </v-btn>
              </v-col>
              <v-col md="12" v-else>
                <template v-if="selectedEvent.type == 'Trainer'">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :class="
                          selectedEvent.is_present == 0
                            ? 'default-color'
                            : selectedEvent.is_present == 1
                            ? 'green-color'
                            : 'red-color'
                        "
                        dark
                        class="text-decoration-none"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{
                          selectedEvent.is_present == 0
                            ? "Attendance"
                            : selectedEvent.is_present == 1
                            ? "Attended"
                            : "No show"
                        }}
                      </v-btn>
                    </template>
                    <v-list v-if="selectedEvent.is_present == 0">
                      <v-list-item
                        @click="
                          confirmAttendance({
                            facility_booking_id:
                              selectedEvent.facility_booking_id,
                            training_sessions_id:
                              selectedEvent.training_sessions_id,
                            operation: 'present',
                          })
                        "
                      >
                        <v-list-item-title>Mark as attended</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="
                          confirmAttendance({
                            facility_booking_id:
                              selectedEvent.facility_booking_id,
                            training_sessions_id:
                              selectedEvent.training_sessions_id,
                            operation: 'absent',
                          })
                        "
                      >
                        <v-list-item-title>Mark no show</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-sheet>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
    <TrainerCustomerAttandanceDialog
      :key="refreshKey"
      :trainerId="trainerId"
      :showStudent="showStudent"
      :selectedEvent="selectedEvent"
      @close="closeShowStudentDialog"
    />
  </div>
</template>

<script>
import TrainerCustomerAttandanceDialog from "../TrainerCustomerAttandanceDialog.vue";
export default {
  props: {
    trainerId: { type: Number, default: null },
  },
  components: {
    TrainerCustomerAttandanceDialog,
  },
  data() {
    return {
      type: "week",
      types: [
        {
          text: "Month",
          value: "month",
        },
        {
          text: "Week",
          value: "week",
        },
        {
          text: "Day",
          value: "day",
        },
      ],
      mode: "column",
      modes: ["stack", "column"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      value: "",
      schedules: [],
      selectedElement: null,
      selectedOpen: false,
      selectedEvent: {},
      categories: ["Trainer Customer", "Academy"],
      ready: false,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      showStudent: false,
      programName: null,
      refreshKey: 1,
    };
  },
  computed: {
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
  },

  mounted() {
    this.ready = true;
    this.scrollToTime();
    this.updateTime();
  },

  methods: {
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);
      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    getEventColor(event) {
      return event.color;
    },
    getTrainerSchedules({ start, end }) {
      this.schedules = [];
      this.showLoader("Loading");
      this.$http
        .get(
          `/venues/trainers/schedules/${this.trainerId}?start_date=${start.date}&end_date=${end.date}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            response.data.data.forEach((time) => {
              var start = new Date(time.start_date + "T" + time.start_time);
              var end = new Date(time.end_date + "T" + time.end_time);
              this.schedules.push({
                name: time.name,
                start: start,
                end: end,
                color: time.type == "trainer" ? "#00b0af" : "#052b48",
                timed: true,
                start_time: time.start_time,
                end_time: time.end_time,
                type: time.type == "trainer" ? "Trainer" : "Academy",
                type_name: time.type_name,
                category:
                  time.type == "trainer" ? "Trainer Customer" : "Academy",
                facility_name: time.facility_name,
                is_reschedule: time.is_reschedule,
                schedule_id: time.schedule_id,
                reschedule_id: time.reschedule_id,
                weekdays: time.weekdays,
                facility_booking_id: time.facility_booking_id,
                training_sessions_id: time.training_sessions_id,
                is_present: time.is_present,
              });
            });

            response.data.unavailable_time.forEach((time) => {
              var start = new Date(time.date + "T" + time.start);
              var end = new Date(time.date + "T" + time.end);
              this.schedules.push({
                name: "N/A",
                start: start,
                end: end,
                start_time: time.start,
                end_time: time.end,
                color: "#d9d9d9",
                timed: true,
                type: "Trainer Not Available",
              });
            });

            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    confirmAttendance(data) {
      let msg =
        "This will mark the attendance of the customer. By clicking Yes you can confirm the operation.";
      this.confirmModel = {
        id: parseInt(data.facility_booking_id),
        title: `Do you want to mark the attendance?`,
        description: msg,
        type: "attendance",
        data: data,
      };
    },
    confirmActions(data) {
      if (data.type == "attendance") {
        this.markAttendance(data.data);
      }
      this.$forceUpdate();
      this.confirmModel.id = null;
    },

    markAttendance(data) {
      console.log(data);
      this.showLoader("Loading...");
      var formData = new FormData();
      formData.append("facility_booking_id", 9);
      formData.append("training_session_id", data.training_sessions_id);
      this.$http
        .post(
          `venues/trainers/customers/sessions/attendance/${data.operation}`,
          formData
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            // const data = response.data.data;
            this.getTrainerSchedules({
              start: this.$refs.calendar.lastStart,
              end: this.$refs.calendar.lastEnd,
            });
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    viewStudents(selectedEvent) {
      this.selectedEvent = selectedEvent;
      this.showStudent = true;
      this.programName = selectedEvent.name;
      this.refreshKey = this.refreshKey + 1;
      this.$forceUpdate();
      // this.$emit("viewStudents", selectedEvent);
    },
    closeShowStudentDialog() {
      this.showStudent = false;
    },
  },
};
</script>

<style>
.details_title {
  font-weight: 600;
  text-transform: uppercase;
  color: rgb(0, 89, 118) !important;
}

.details_subtitle {
  font-size: 14px;
  padding: 3px 0px;
  text-align: left;
  font-weight: 700;
}

.details_data {
  font-size: 14px;
  padding: 3px 0px;
  text-align: left;
  font-weight: 400;
}

.v-current-time {
  height: 2px;
  background-color: #521e19;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
}
.first::before {
  content: "";
  position: absolute;
  background-color: #643733;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: -5px;
  margin-left: -6.5px;
}
</style>
