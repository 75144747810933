<template>
  <v-dialog v-model="scheduleDialoge" width="600" @input="close">
    <v-form ref="form">

      <v-card>
        <v-card-text >
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold" text="Schedule Session" style="color: black" >
                </SvgIcon>
                <v-btn  fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>


          <v-row>
            <v-col cols="12">
              <v-card
                  v-for="(customer, index) in scheduleForm.customers"
                  :key="customer.id"
                  flat
                  class="d-flex justify-space-between pa-3 mb-10 shadow-0 bordered"
              >
                <v-btn
                    fab
                    color="error"
                    @click="scheduleForm.customers.splice(index, 1)"
                    v-if="
                  scheduleForm.customers.length > 1 &&
                  customer.trainer_booking_id != scheduleForm.trainer_booking_id
                "
                    absolute
                    top
                    right
                    x-small
                ><v-icon small>mdi-delete</v-icon>
                </v-btn>
                <div>
                  <div>Name</div>
                  <div class="subtitle-1 font-weight-black blue-text">
                    {{ customer.first_name }} {{ customer.last_name }}
                  </div>
                </div>
                <div>
                  <div>Package name</div>
                  <div class="subtitle-1 font-weight-black blue-text">
                    {{ customer.package_name }}
                  </div>
                </div>
                <div>
                  <div>Duration</div>
                  <div class="subtitle-1 font-weight-black blue-text">
                    {{ customer.duration }} min
                  </div>
                </div>

                <div>
                  <div>Sessions</div>
                  <div class="subtitle-1 font-weight-black blue-text">
                    {{ customer.remaining_sessions }} / {{ customer.sessions }}
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <v-row
              class="d-flex justify-center"
              v-if="scheduleForm.people > scheduleForm.customers.length"
          >
            <v-col md="8">
              <customer-search
                  outlined
                  background-color="#fff"
                  dense
                  v-model="search"
                  :packageType="scheduleForm.package_type"
                  :duration="scheduleForm.duration"
                  :people="scheduleForm.people"
                  :trainerBookingId="scheduleForm.trainer_booking_id"
                  :venueServiceId="scheduleForm.venue_service_id"
                  label="Search customer"
              >
              </customer-search>
            </v-col>
            <v-col md="2"
            ><v-btn class="teal-color" @click="addCustomer" dark text height="38"
            >Add</v-btn
            ></v-col
            >
          </v-row>
          <v-row dense justify="center">
            <v-col md="6">
              <date-field
                  v-model="scheduleForm.date"
                  @change="getScheduleData"
                  :backFill="checkBackfillPermission($modules.trainers.management.slug)"
              ></date-field>
            </v-col>
            <v-col md="6">
              <v-select
                  :items="trainers"
                  label="Trainer*"
                  item-text="name"
                  item-value="trainer_id"
                  v-model="scheduleForm.trainer_id"
                  :rules="[(v) => !!v || 'Trainer is required']"
                  required
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  @change="getTrainerServices"
                  background-color="#fff"
              ></v-select>
            </v-col>

            <v-col md="4">
              <v-select
                  :items="startTimes"
                  label="From*"
                  item-text="formatted"
                  item-value="time"
                  :rules="[(v) => !!v || 'From time is required']"
                  v-model="scheduleForm.start_time"
                  @change="startTimeChange()"
                  required
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
              ></v-select>
            </v-col>
            <v-col md="4">
              <v-select
                  :items="endTimes"
                  label="To*"
                  item-text="formatted"
                  item-value="time"
                  @change="getNumberOfSessions"
                  v-model="scheduleForm.end_time"
                  :rules="[(v) => !!v || 'To time is required']"
                  required
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
              ></v-select>
            </v-col>
            <v-col md="4">
              <v-text-field
                  background-color="#fff"
                  v-model="scheduleForm.scheduled_sessions"
                  label="No of Sessions"
                  outlined
                  :rules="[
                    (v) => !!v || 'No of Sessions required.',
                    (v) => !isNaN(v) || 'Unknown session number',
                  ]"
                  required
              ></v-text-field>
            </v-col>
            <v-col md="12" style="margin-top: -30px">
              <div class="d-flex justify-center">
                <div style="padding-left: 10px; margin-top: 25px; font-size: 2vh">
                  Do you want book a facility?
                </div>
                <v-radio-group
                    class="pa-2"
                    @change="getScheduleAvailableFacilities"
                    v-model="scheduleForm.in_facility"
                    row
                >
                  <v-radio label="Yes" color="primary" value="1"></v-radio>
                  <v-radio label="No" color="primary" value="0"></v-radio>
                </v-radio-group>
              </div>
            </v-col>
            <v-col md="6" v-if="scheduleForm.in_facility == 1">
              <v-select
                  background-color="#fff"
                  v-model="scheduleForm.training_service_id"
                  label="Service"
                  :items="trainerServices"
                  @change="getScheduleAvailableFacilities"
                  item-text="name"
                  item-value="id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
              >
              </v-select>
            </v-col>
            <v-col md="6" v-if="scheduleForm.in_facility == 1">
              <v-select
                  background-color="#fff"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  v-model="scheduleForm.facility_id"
                  label="Facility"
                  :rules="[
                    (v) => !!v || 'Facility is required for in facility schedules',
                  ]"
                  :items="facilities"
                  item-text="name"
                  item-value="id"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn

              color=" "
              class="ma-2  "
              text @click="close">Close</v-btn>
          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              @click="scheduleTraining"
            >Schedule</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import moment from "moment";
import CustomerSearch from "../../../components/Trainer/CustomerSearch";
import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {
  props: {
    "order-id": { type: Number, default: null },
  },
  components: {
    SvgIcon,
    CustomerSearch,
  },
  watch: {
    orderId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getScheduleData();
          this.scheduleForm = {
            date: moment().format("YYYY-MM-DD"),
            order_id: val,
            customers: [],
          };
        }
      },
    },
  },
  data() {
    return {
      scheduleDialoge: false,
      scheduleForm: { customers: [] },
      facilities: [],
      startTimes: [],
      endTimes: [],
      trainers: [],
      trainerServices: [],
      duration: null,
      search: {},
    };
  },
  methods: {
    close() {
      this.scheduleDialoge = false;
      this.$emit("close");
    },
    getNumberOfSessions() {
      let startTime = this.endTimes.find(
        (item) => item.time == this.scheduleForm.start_time
      );
      let endTime = this.endTimes.find((item) => item.time == this.scheduleForm.end_time);
      if (startTime && endTime) {
        let session = endTime.quantity - startTime.quantity;
        if (session < 1) {
          this.showError("End time should be greater than start time");
          return;
        }
        this.scheduleForm.scheduled_sessions = session;
        this.$forceUpdate();
        if (
          this.scheduleForm.training_service_id &&
          this.scheduleForm.in_facility == "1"
        ) {
          this.getScheduleAvailableFacilities();
        }
      }
    },
    getScheduleData() {
      this.$http
        .get(`venues/trainers/customers/schedule/data?order_id=${this.orderId}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            if (this.scheduleForm.customers.length < 1) {
              this.scheduleForm.customers = [data.customer];
            }
            this.scheduleForm.people = data.training_package.people;
            this.scheduleForm.package_type = data.training_package.package_type;
            this.scheduleForm.duration = data.training_package.duration;
            this.scheduleForm.trainer_booking_id = data.customer.trainer_booking_id;
            this.scheduleForm.venue_service_id = data.training_package.venue_service_id;
            let lIndex = data.times.length - 1;
            this.startTimes = [];
            let currentDay = this.scheduleForm.date == moment().format("YYYY-MM-DD");
            if (
              currentDay &&
              !this.checkBackfillPermission(this.$modules.trainers.management.slug)
            ) {
              for (let i = 0; i < lIndex; i++) {
                if (
                  this.minutesOfDay(moment()) <=
                  this.minutesOfDay(moment(data.times[i].time, "HH:mm:ss"))
                ) {
                  this.startTimes.push(data.times[i]);
                }
              }
            } else {
              this.startTimes = data.times;
            }

            this.endTimes = data.times.slice(0);
            this.trainers = data.trainers;
            this.scheduleDialoge = true;
            this.duration = data.duration;
            setTimeout(() => {
              this.$refs.form.resetValidation();
            });
            this.getScheduleAvailableFacilities();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    minutesOfDay(m) {
      return m.minutes() + m.hours() * 60;
    },
    startTimeChange() {
      let endTime = moment(this.scheduleForm.start_time, "HH:mm:ss")
        .add(this.duration, "minutes")
        .format("HH:mm:ss");
      if (endTime == "00:00:00") {
        endTime = "23:59:00";
      }
      this.scheduleForm.end_time = endTime;
      setTimeout(() => {
        this.getNumberOfSessions();
      });
    },
    getScheduleAvailableFacilities() {
      this.facilities = [];
      if (
        !this.scheduleForm.start_time ||
        !this.scheduleForm.end_time ||
        !this.scheduleForm.training_service_id ||
        this.scheduleForm.in_facility == "0"
      )
        return;
      this.$http
        .get(
          `venues/trainers/customers/schedule/facilities?start_time=${this.scheduleForm.start_time}&end_time=${this.scheduleForm.end_time}&date=${this.scheduleForm.date}&training_service_id=${this.scheduleForm.training_service_id}`
        )
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            this.facilities = data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getTrainerServices(trainerId) {
      this.$http
        .get(`venues/trainers/services/${trainerId}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            this.trainerServices = data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    scheduleTraining() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader();
      let postData = {formData : btoa(JSON.stringify(this.scheduleForm))}
      this.$http
        .post("venues/trainers/customers/schedule", postData)
        .then((response) => {
          if (response.status == 200) {
            this.scheduleDialoge = false;
            this.hideLoader();
            this.showSuccess("Trainer Session Scheduled.");
            this.$emit("complete");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    addCustomer() {
      if (!this.search.trainer_booking_id) {
        this.showError("Error: Please select a customer");
        return;
      }
      let checkExistingCustomer = this.scheduleForm.customers.findIndex(
        (item) => item.trainer_booking_id == this.search.trainer_booking_id
      );
      if (checkExistingCustomer != -1) {
        this.showError("Error: Customer already added");
        return;
      }
      this.scheduleForm.customers.push(this.search);
      this.search = {};
    },
  },
};
</script>

<style></style>
