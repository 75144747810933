var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showStudent)?_c('v-dialog',{attrs:{"width":"60%"},on:{"click:outside":_vm.close},model:{value:(_vm.showStudent),callback:function ($$v) {_vm.showStudent=$$v},expression:"showStudent"}},[_c('v-card',{staticClass:"fill-height"},[_c('v-card-title',{staticClass:"headline d-flex justify-space-between",staticStyle:{"height":"90px"}},[_c('div',{staticClass:"text-left d-flex align-center"},[_vm._v("Customer Attendance")]),_c('div',{staticClass:"text-left d-flex align-center"},[_vm._v(" "+_vm._s(this.selectedEvent.name)+" ")]),_c('div',{staticClass:"text-left d-flex align-center"},[_vm._v(" "+_vm._s(_vm._f("dayDateFormat")(this.selectedEvent.start))+" ")])]),_c('v-card-text',{staticClass:"pa-2"},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"tableHeader text-center"},[_vm._v("Name")]),_c('th',{staticClass:"tableHeader text-center"},[_vm._v("Mobile")]),_c('th',{staticClass:"tableHeader text-center"},[_vm._v("Email")]),_c('th',{staticClass:"tableHeader text-center"},[_vm._v("Payment")]),_c('th',{staticClass:"tableHeader text-center"},[_vm._v("Status")]),_c('th',{staticClass:"tableHeader text-center"},[_vm._v("Attendance")])])]),(_vm.students.length)?_c('tbody',_vm._l((_vm.students),function(student,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(student.name))]),_c('td',[_vm._v(_vm._s(student.mobile))]),_c('td',[_vm._v(_vm._s(student.email))]),_c('td',{staticStyle:{"text-align":"center"}},[(student.order_status == 5)?_c('v-btn',{attrs:{"text":"","color":"warning","plain":""}},[_vm._v("Unpaid")]):_vm._e(),(student.order_status == 4)?_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("Paid")]):_vm._e()],1),_c('td',{staticClass:"md-table-cell text-center"},[_c('v-btn',{attrs:{"text":"","color":student.is_attended == 0
                      ? 'secondary'
                      : student.is_attended == 1
                      ? 'success'
                      : 'error',"dark":"","small":""}},[_vm._v(" "+_vm._s(!student.is_attended ? "NA" : student.is_attended == 1 ? "Attended" : student.is_attended == 2 ? "No show" : "")+" ")])],1),_c('td',{staticClass:"md-table-cell text-center"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"","indeterminate":student.is_attended === 1 ? true : false,"disabled":student.is_attended === 1 ? true : false},model:{value:(student.is_check),callback:function ($$v) {_vm.$set(student, "is_check", $$v)},expression:"student.is_check"}})],1)])])}),0):_c('tbody',[_c('td',{attrs:{"colspan":"9"}},[_c('v-card-text',{staticClass:"pa-8 text-center"},[_c('h3',[_vm._v("No students found!")])])],1)])]},proxy:true}],null,false,67411103)})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticStyle:{"width":"200px","margin-top":"24px","margin-right":"20px"}},[_c('v-select',{attrs:{"items":_vm.trainers,"label":"Choose trainer","item-text":"first_name","item-value":"trainer_id","outlined":"","dense":"","background-color":"#fff"},model:{value:(_vm.selectedTrainer),callback:function ($$v) {_vm.selectedTrainer=$$v},expression:"selectedTrainer"}})],1),_c('v-btn',{staticClass:"blue-color",attrs:{"dark":""},on:{"click":_vm.close}},[_vm._v("Close")]),_c('div',{staticStyle:{"margin-left":"7px"}},[(_vm.students && !_vm.students.length == 0 && !_vm.disableAttendance)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"default-color",attrs:{"dark":""}},'v-btn',attrs,false),on),[_vm._v(" Attendance ")])]}}],null,false,4199982329)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.markAttendance('present')}}},[_c('v-list-item-title',[_vm._v("Mark as attended")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.markAttendance('absent')}}},[_c('v-list-item-title',[_vm._v("Mark no show")])],1)],1)],1):_vm._e()],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }