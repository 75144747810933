<template>
  <v-dialog
    v-if="showStudent"
    v-model="showStudent"
    width="60%"
    @click:outside="close"
  >
    <v-card class="fill-height">
      <v-card-title
        class="headline d-flex justify-space-between"
        style="height: 90px"
      >
        <div class="text-left d-flex align-center">Customer Attendance</div>
        <div class="text-left d-flex align-center">
          {{ this.selectedEvent.name }}
        </div>

        <div class="text-left d-flex align-center">
          {{ this.selectedEvent.start | dayDateFormat }}
        </div>
      </v-card-title>
      <v-card-text class="pa-2">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="tableHeader text-center">Name</th>
                <th class="tableHeader text-center">Mobile</th>
                <th class="tableHeader text-center">Email</th>
                <th class="tableHeader text-center">Payment</th>
                <th class="tableHeader text-center">Status</th>
                <th class="tableHeader text-center">Attendance</th>
                <!-- <th class="tableHeader text-center">Reschedule</th> -->
                <!-- <th class="tableHeader text-center">Refund</th> -->
              </tr>
            </thead>
            <tbody v-if="students.length">
              <tr v-for="(student, index) in students" :key="index">
                <td>{{ student.name }}</td>
                <td>{{ student.mobile }}</td>
                <td>{{ student.email }}</td>
                <td style="text-align: center">
                  <v-btn
                    text
                    color="warning"
                    plain
                    v-if="student.order_status == 5"
                    >Unpaid</v-btn
                  >
                  <v-btn text color="primary" v-if="student.order_status == 4"
                    >Paid</v-btn
                  >
                </td>
                <td class="md-table-cell text-center">
                  <v-btn
                    text
                    :color="
                      student.is_attended == 0
                        ? 'secondary'
                        : student.is_attended == 1
                        ? 'success'
                        : 'error'
                    "
                    dark
                    small
                  >
                    {{
                      !student.is_attended
                        ? "NA"
                        : student.is_attended == 1
                        ? "Attended"
                        : student.is_attended == 2
                        ? "No show"
                        : ""
                    }}
                  </v-btn>
                </td>
                <td class="md-table-cell text-center">
                  <div class="d-flex justify-center">
                    <v-checkbox
                      class="mt-0"
                      v-model="student.is_check"
                      hide-details
                      :indeterminate="student.is_attended === 1 ? true : false"
                      :disabled="student.is_attended === 1 ? true : false"
                    ></v-checkbox>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <td colspan="9">
                <v-card-text class="pa-8 text-center">
                  <h3>No students found!</h3>
                </v-card-text>
              </td>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <!-- <v-btn
        v-if="!disableAttendance && !disableReschedule"
        dark
        class="yellow-color"
        @click="(rescheduleDialog = true), (customersLogDialog = false)"
        >Reschedule Class</v-btn
      > -->
        <v-spacer></v-spacer>
        <div style="width: 200px; margin-top: 24px; margin-right: 20px">
          <v-select
            v-model="selectedTrainer"
            :items="trainers"
            label="Choose trainer"
            item-text="first_name"
            item-value="trainer_id"
            outlined
            dense
            background-color="#fff"
          ></v-select>
        </div>
        <v-btn dark class="blue-color" @click="close">Close</v-btn>

        <div style="margin-left: 7px">
          <v-menu
            offset-y
            v-if="students && !students.length == 0 && !disableAttendance"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="default-color" dark v-bind="attrs" v-on="on">
                Attendance
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="markAttendance('present')">
                <v-list-item-title>Mark as attended</v-list-item-title>
              </v-list-item>
              <v-list-item @click="markAttendance('absent')">
                <v-list-item-title>Mark no show</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    trainerId: { type: Number, default: 0 },
    showStudent: { type: Boolean, default: false },
    selectedEvent: { type: Object, default: null },
  },
  data() {
    return {
      selectedWorkshopScheduleId: null,
      timings: [],
      trainersList: [],
      programId: null,
      students: [],
      trainers: [],
      disableAttendance: false,
      selectedTrainer: null,
      selectedCellDate: null,
    };
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    weekdays() {
      return this.$store.getters.getWeekdays.data;
    },
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.showStudent && this.selectedEvent) {
      this.selectedCellDate = this.selectedEvent.start
        .toISOString()
        .slice(0, 10);
      this.$forceUpdate();
      this.getTrainers();
    }
  },
  methods: {
    getTrainers() {
      this.$http
        .get(`venues/trainers/short`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.trainersList = response.data.data;
            this.getBookedCustomer(
              this.selectedEvent.schedule_id,
              this.selectedEvent.weekdays,
              this.selectedEvent.start_time,
              this.selectedEvent.is_reschedule,
              this.selectedCellDate
            );
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    getFacilities(venueServiceId) {
      if (this.venueServiceId > 0) return;
      this.$http
        .get(`venues/facilities/short?venue_service_id=${venueServiceId}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.reschedule.facilities = response.data.data;
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    getBookedCustomer(
      workshopScheduleId,
      weekdays,
      time,
      is_reschedule,
      from_date
    ) {
      let reschedule_id = null;
      if (is_reschedule == 1) {
        reschedule_id = this.selectedEvent.reschedule_id;
      }
      this.disableReschedule = false;
      this.showLoader("Loading");
      this.students = [];
      this.$http
        .get(
          `venues/workshops/schedules/booking/customer?workshop_schedule_id=${workshopScheduleId}&weekdays=${weekdays}&start_time=${time}&date=${from_date}&is_reschedule=${is_reschedule}&from_date=${from_date}&reschedule_id=${reschedule_id}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.students = response.data.data;
            if (
              this.students.findIndex(
                (students) => students.is_attended == true
              ) != -1
            ) {
              this.disableReschedule = true;
            }
            this.selectedTrainer = null;
            this.students.forEach((element) => {
              element.is_check = false;
              let selTrainer = null;
              if (element.trainer_id) {
                // console.log("if");
                selTrainer = this.trainersList.find(
                  (x) => x.id == element.trainer_id
                );
                if (selTrainer) {
                  this.selectedTrainer = selTrainer.id;
                  let obj = {
                    trainer_id: selTrainer.id,
                    first_name: selTrainer.name,
                  };
                  this.trainers.push(obj);
                }
              } else {
                // console.log("else");
                selTrainer = this.trainersList.find(
                  (x) => x.id == this.trainerId
                );
                if (selTrainer) {
                  this.selectedTrainer = selTrainer.id;
                  let obj = {
                    trainer_id: selTrainer.id,
                    first_name: selTrainer.name,
                  };
                  this.trainers.push(obj);
                }
              }
            });
            // console.log("sel", this.selectedTrainer);
            // console.log("ttt", this.trainers);
            this.$forceUpdate();
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    markAttendance(type) {
      if (!this.selectedTrainer) {
        this.showError("Please select trainer");
        return true;
      }

      var data = [];

      this.students.forEach((student) => {
        var obj = {};

        obj = {
          attendance_id: student.attendance_id,
          order_id: student.order_id,
          date: this.selectedCellDate,
          trainer_id: this.selectedTrainer,
          booking_schedule_id: student.workshop_booking_schedule_id,
          workshop_booking_id: student.workshop_booking_id,
          workshop_schedule_id: student.workshop_schedule_id,
        };

        if (student.is_check) {
          if (type == "present") {
            obj.is_attended = student.is_check ? 1 : 0;
          }
          if (type == "absent") {
            obj.is_attended = student.is_check ? 2 : 0;
          }
        }
        if (student.is_check != 0) data.push(obj);
      });

      if (data.length > 0) {
        this.showLoader("Loading...");
        this.$http
          .post(`venues/workshops/schedules/booking/attendance`, {
            attendance: data,
          })
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              // this.customersLogDialog = false;
              this.students.forEach((el) => {
                if (el.is_check) {
                  if (type == "present") {
                    el.is_attended = 1;
                    el.is_check = 0;
                  }
                  if (type == "absent") {
                    el.is_attended = 2;
                    el.is_check = 0;
                  }
                  this.$forceUpdate();
                }
              });
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.customersLogDialog = false;
            this.errorChecker(error);
          });
      }
    },

    getWeekdays(days) {
      let dayName = "";
      days.forEach((element) => {
        let findValue = this.$store.getters.getWeekdays.data.find(
          (y) => y.bit_value == element
        );
        if (findValue) {
          dayName = dayName.concat(findValue.name.substring(0, 3), ",");
        }
      });
      return dayName.slice(0, -1);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.headline div {
  font-size: 20px;
}
</style>
