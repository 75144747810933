<template>
  <v-container fluid>
    <v-row class="">
      <v-col md="4" class="ml-3 mt-3">
        <date-period-field
          :dense="false"
          :dateType="dateToggle"
          @periodChange="dateChange"
          class-name="q-text-field shadow-0"
          :outlined="true"
        ></date-period-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col md="3">
        <v-btn-toggle
          borderless
          class="q-tabs"
          v-model="dateToggle"
        >
          <v-btn height="54" value="DATE">Day</v-btn>
          <v-btn height="54" value="MONTH">Month</v-btn>
          <v-btn height="54" value="YEAR">Year</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-spacer></v-spacer>
      <v-col md="2"> </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <BarChart
          :exportPermission="
            checkExportPermission($modules.trainers.management.slug)
          "
          v-bind="bookings"
        ></BarChart>
      </v-col>

      <v-col cols="12">
        <BarChart
          :exportPermission="
            checkExportPermission($modules.trainers.management.slug)
          "
          v-bind="sales"
        ></BarChart>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BarChart from "@/components/Chart/BarChart";
import moment from "moment";
import DatePeriodField from "@/components/Fields/DatePeriodField.vue";
export default {
  components: {
    DatePeriodField,
    BarChart,
  },
  props: {
    "trainer-id": { type: Number, default: null },
  },
  watch: {
    trainerId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.callGraph();
        }
      },
    },
  },
  data() {
    return {
      agePieChart: null,
      genderPieChart: null,
      countryPieChart: null,
      dateToggle: "DATE",
      venueServiceIds: [],
      productTypeIds: [],
      productIds: [],
      date1: moment().subtract(15, "days").format("YYYY-MM-DD"),
      date2: moment().format("YYYY-MM-DD"),
      bookings: {
        heading: "Bookings",
        type: "totalBooking",
        fullscreenFlag: true,
        yTitle: "Bookings",
        xTitle: "Dates",
        chartData: [],
        exportData: [],
        loader: true,
        heighest: 0,
      },
      sales: {
        heading: "Sales",
        type: "totalSales",
        fullscreenFlag: true,
        yTitle: "Sales",
        xTitle: "Dates",
        chartData: [],
        exportData: [],
        loader: true,
        heighest: 0,
      },
      stack: "all",
    };
  },

  methods: {
    dateChange(data) {
      this.date1 = data.date1;
      this.date2 = data.date2;
      this.callGraph();
    },
    callGraph() {
      this.sales.loader = true;
      this.bookings.loader = true;
      let url = `?trainer_id=${this.trainerId}`;
      url += this.getApiFilter();
      this.$http
        .get(`venues/trainers/graph${url}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            let sData = this.getGraphData(data.sales, "Sales");
            this.sales.heighest = Math.ceil(sData.largest * 1.1);
            this.sales.chartData = sData.data;
            this.sales.exportData = data.sales;
            this.sales.loader = false;
            let bData = this.getGraphData(data.bookings, "Bookings");
            this.bookings.heighest = Math.ceil(bData.largest * 1.1);
            this.bookings.chartData = bData.data;
            this.bookings.exportData = data.bookings;
            this.bookings.loader = false;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getApiFilter() {
      let url = `&date_type=${this.dateToggle.toLowerCase()}&start_date=${
        this.date1
      }&end_date=${this.date2}`;
      return url;
    },
    getGraphData(data, type) {
      var graphData = [];
      let largest = 0;
      if (data.length > 0) {
        let keys = Object.keys(data[0]).filter(
          (ele) => ele.toLowerCase() != "date"
        );
        let keysLength = keys.length;

        data.forEach((element, index) => {
          let di = 0;
          let lar = 0;
          for (let i = 0; i < keysLength; i++) {
            if (index == 0) {
              graphData[i] = {};
              graphData[i].data = {};
              graphData[i].name = keys[i];
              lar = lar + parseInt(element[keys[i]]);
              graphData[i].data[element.Date] = element[keys[i]];
              graphData[keysLength] = {};
              graphData[keysLength].name = "Total";
              graphData[keysLength].data = {};
              graphData[keysLength].data[element.Date] = 0;
              di = di + 1;
            } else {
              lar = lar + parseInt(element[keys[i]]);
              graphData[i].data[element.Date] = element[keys[i]];
              di = di + 1;
            }
          }
          graphData[keysLength].data[element.Date] = 0;
          if (largest < lar) {
            largest = lar;
          }
        });
        if (keysLength == 1 && keys[0] == type) {
          graphData[0].library = {};
          graphData[0].library.datalabels = {
            color: "rgba(0,0,49)",
            align: "end",
            anchor: "end",
            font: {
              size: 12,
              weight: 700,
            },
          };
        }
        graphData[keysLength].library = {};
        graphData[keysLength].library.datalabels = {
          color: function () {
            return "rgba(0,0,49)";
          },
          formatter: (value, ctx) => {
            let total = ctx.chart.$totalizer.totals[ctx.dataIndex];
            if (type == "Bookings") {
              let data = new Intl.NumberFormat("en-US", {
                maximumSignificantDigits: 3,
              }).format(total);
              return data + (total > 1 ? " bookings" : " booking");
            } else if (type == "Sales") {
              var formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: this.$store.getters.currencyCode,
                minimumFractionDigits: 0,
              });
              return formatter.format(total);
            } else {
              return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: this.$store.getters.currencyCode,
                maximumSignificantDigits: 0,
              }).format(total);
            }
          },
          font: {
            size: 12,
            weight: 700,
          },
          align: "end",
          anchor: "end",
          display: function (ctx) {
            return (
              ctx.datasetIndex === ctx.chart.$totalizer.utmost &&
              ctx.chart.$totalizer.totals[ctx.dataIndex] !== 0
            );
          },
        };
      }
      return { data: graphData, largest: largest };
    },
  },
};
</script>

<style>
</style>