<template>
  <v-dialog
    v-model="availabilityFormDialogue"
    scrollable
    persistent
    width="40%"
    content-class=""
  >
    <v-form ref="form">

      <v-card class="" >
        <v-card-text class="border-bottom">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold" text="Trainers Availability Hours" style="color: black" >
                </SvgIcon>
                <v-btn  fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>

          <v-row dense>
            <v-col cols="12">
              <v-card
                  class="pa-2 mt-6 mx-2"
                  style="background-color: rgba(240, 245, 249, 1) ; border-radius: 8px"
                  v-for="(timeSlot, k) in timeSlotList"
                  :key="k"
                  max-height="250px"
                  min-height="100px"
                  elevation="0"
              >
                <v-row dense>
                  <v-col lg="4" md="12">
                    <label for="">Select Days</label>
                    <v-autocomplete
                        :items="allWeekdays"
                        item-text="name"
                        item-value="bit_value"
                        multiple
                        outlined
                        background-color="#fff"
                        validate-on-blur
                        class="q-autocomplete shadow-0"
                        hide-details="auto"
                        dense
                        :rules="[(v) => v.length > 0 || 'Days Applicable is required']"
                        v-model="timeSlot.weekdays"
                    >
                      <template
                          v-if="allWeekdays.length == timeSlot.weekdays.length"
                          v-slot:selection="{ index }"
                      >
                        <span v-if="index == 0">All Days</span>
                      </template>
                      <template v-else v-slot:selection="{ item, index }">
                        <span v-if="index == 0">{{ item.name }}</span>
                        <span v-if="index == 1">,{{ item.name }}</span>
                        <span v-if="index == 2">, ...</span>
                      </template>
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="toggle(k)">
                          <v-list-item-action>
                            <v-icon
                                :color="timeSlot.weekdays.length > 0 ? 'indigo darken-4' : ''"
                            >{{ getIcon(k) }}</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col lg="4" md="6">
                    <label for="">Start Time</label>

                    <v-select
                        :items="timings.slice(0, timings.length - 1)"
                        item-text="text"
                        item-value="value"
                        :rules="[(v) => !!v || 'From time is required']"
                        v-model="timeSlot.start_time"
                        required
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        class="q-autocomplete shadow-0"
                        hide-details="auto"
                        dense
                    ></v-select>
                  </v-col>
                  <v-col lg="4" md="6">
                    <label for="">End Time</label>

                    <v-select
                        :items="timings.slice(1)"
                        item-text="text"
                        item-value="value"
                        v-model="timeSlot.end_time"
                        :rules="[(v) => !!v || 'To time is required']"
                        required
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        class="q-autocomplete shadow-0"
                        hide-details="auto"
                        dense
                    ></v-select>
                  </v-col>
                  <v-tooltip bottom v-if="timeSlotList.length > 1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="deleteTimingTemplate(k)"
                          fab
                          x-small
                          absolute
                          top
                          right
                          text
                      >
                        <DeleteIcon/>
                      </v-btn>
                    </template>
                    Delete
                  </v-tooltip>
                </v-row>
              </v-card>
            </v-col>
          </v-row>


          <v-row dense>
          <v-col cols="12">
               <span class="text-underline font-semibold text-base ml-2" style="cursor: pointer ; color: rgba(79, 174, 175, 1)"  @click="
                  timeSlotList.push({
                    weekdays: [],
                    start_time: null,
                    end_time: null,
                  })
                "> + Add New </span>

          </v-col>
          </v-row>
        </v-card-text>




        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2 " @click="close" text
          >Close</v-btn
          >
          <v-btn class="ma-2 white--text blue-color shadow-0" @click="addOrUpdateTrainerCustomer"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-dialog>
</template>

<script>
import moment from "moment";
import trainer from "@/mixins/trainer";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import DeleteIcon from "@/assets/images/misc/delete-bg-icon.svg";


export default {
  components: {SvgIcon , DeleteIcon},
  props: {
    availabilityFormDialogue: {
      type: Boolean,
      default: false,
    },
    trainerId: { type: Number, default: null },
    editId: { type: Number, default: null },
    aval: { type: Object, default: null },
  },
  created() {
    this.generateTiming();
  },
  data() {
    return {
      timeSlotList: [
        {
          weekdays: [],
          start_time: null,
          end_time: null,
        },
      ],
      editFlag: false,
      timings: [],
      timeIncrement: 15,
      deleted_timeSlotList: [],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
    };
  },
  watch: {
    availabilityFormDialogue(val) {
      if (val == true) {
        if (this.aval) {
          this.weekdays = this.aval.weekdays;
          this.start_time = this.aval.start_time;
          this.end_time = this.aval.end_time;
        }
      }
      this.loadAvailability();
    },
  },
  computed: {
    allWeekdays() {
      return this.$store.getters.getWeekdays.data;
    },
  },
  mixins: [trainer],
  methods: {
    getFilterUrl() {
      let url = `?page=1&per_page=1000`;
      return url;
    },
    loadAvailability() {
      let url = this.getFilterUrl();
      this.$http
        .get(
          `venues/trainers/availability${
            this.trainerId != null ? "/" + this.trainerId : ""
          }${url}`
        )
        .then((response) => {
          if (response.status == 200) {
            this.timeSlotList = JSON.parse(JSON.stringify(response.data.data));
            if (this.timeSlotList.length == 0) {
              this.timeSlotList = [
                {
                  name: null,
                  weekdays: [],
                  end_time: null,
                  start_time: null,
                },
              ];
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    deleteTimingTemplate(index) {
      if (this.timeSlotList[index].id) {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this templates?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "delete",
        };
      } else {
        this.timeSlotList.splice(index, 1);
      }
    },
    confirmActions(data) {
      this.deleted_timeSlotList.push(this.timeSlotList[data.id].id);
      this.timeSlotList.splice(data.id, 1);
      this.confirmModel = {
        id: null,
        title: null,
        description: null,
      };
    },
    close() {
      this.weekdays = [];
      this.start_time = null;
      this.end_time = null;
      this.$emit("loadAvail");
      this.$emit("close");
    },
    addOrUpdateTrainerCustomer() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader("Loading");

      let formData = new FormData();

      this.timeSlotList.forEach((template, index) => {
        console.log(template);
        for (let key in template) {
          formData.append(
            `timing_slots[${index}][${key}]`,
            typeof template[key] != "object"
              ? template[key]
              : JSON.stringify(template[key])
          );
        }
        formData.set(`timing_slots[${index}][trainer_id]`, this.trainerId);
      });

      if (this.deleted_timeSlotList.length) {
        this.deleted_timeSlotList.forEach((item, index) => {
          formData.append(`deleted_timing_slots[${index}]`, item);
        });
      }
      // formData.append("id", this.editId ?? null);
      // formData.append("weekdays", this.weekdays);
      // formData.append("start_time", this.start_time);
      // formData.append("end_time", this.end_time);

      this.$http
        .post(`venues/trainers/availability/${this.trainerId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=${form._boundary}",
          },
        })
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Configuration saved successfully.");
            this.$emit("loadAvail");
          }
          this.deleted_timeSlotList = [];
          this.$emit("close");
        })
        .catch((error) => {
          this.errorChecker(error);
        });

      this.hideLoader();
    },
    getIcon(index) {
      let icon = "mdi-checkbox-blank-outline";
      if (this.timeSlotList[index].weekdays.length == this.allWeekdays.length) {
        icon = "mdi-close-box";
      }
      if (
        this.timeSlotList[index].weekdays.length > 0 &&
        this.timeSlotList[index].weekdays.length != this.allWeekdays.length
      ) {
        icon = "mdi-minus-box";
      }
      return icon;
    },
    toggle(index) {
      this.$nextTick(() => {
        if (this.timeSlotList[index].weekdays.length == this.allWeekdays.length) {
          this.timeSlotList[index].weekdays = [];
        } else {
          this.timeSlotList[index].weekdays = this.allWeekdays.map((item) => {
            return item.bit_value;
          });
        }
      });
      this.$forceUpdate();
    },
    generateTiming() {
      let currentTime = moment("00:00:00", "HH:mm:ss");
      this.timings = [];
      this.timings.push({
        value: currentTime.format("HH:mm:ss"),
        text: currentTime.format("hh:mm a"),
      });
      let hour = 0;
      while (hour < 24) {
        currentTime = currentTime.add(this.timeIncrement, "minutes");
        let data = {
          value: currentTime.format("HH:mm:ss"),
          text: currentTime.format("hh:mm a"),
        };
        if (data.value == "00:00:00") {
          data.value = "23:59:59";
          this.timings.push(data);
          hour = 24;
        } else {
          this.timings.push(data);
          hour = currentTime.get("hours");
        }
      }
    },
  },
};
</script>

<style>
.trainer_avail_modal_background {
  background: #edf9ff !important;
}
</style>
