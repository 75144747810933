<template>
  <div>

    <v-row v-if="toggleTab === 'logs'">
      <div class="col-12">
        <div>
          <div class="header_title">
            <v-row class="">
              <v-col cols="12">
                <div class="d-flex justify-space-between align-center ">
                  <SvgIcon class="text-2xl font-semibold" text="Students" >
                  </SvgIcon>


                <v-btn-toggle
                    borderless
                    class="q-tabs "
                    v-model="searchParams.package_type"
                    mandatory
                    @change="getFilterData"
                >
                  <v-btn value="all" style="height: 50px">All</v-btn>
                  <v-btn value="I"  style="height: 50px">Individual</v-btn>
                  <v-btn value="C" style="height: 50px">Couple</v-btn>
                  <v-btn value="G"  style="height: 50px">Group</v-btn>
                </v-btn-toggle>

                <v-btn
                    color=" darken-1"
                    class="white--text blue-color"
                    text
                    height="48"
                    v-if="
                            checkWritePermission(
                              $modules.trainers.management.slug
                            ) && trainer.status_id == 1
                          "
                    @click="addCustomer"
                >
                  <AddIcon/>
                  <span class="ml-1">Add Student</span>
                </v-btn>
                </div>
              </v-col>



            </v-row>
          </div>

        </div>
      </div>
    </v-row>

    <div class="md-card md-theme-default mt-8 shadow rounded-5">
      <div class="md-card-content md-card-table">
        <div>
          <div class="table-responsive">
            <table class="table border-collapse ">
              <thead class="">
              <tr class="opacity-70 tr-neon tr-rounded ">
                <th class="">
                  <div class="" style="min-width: 100px;">Name</div>
                  <div class="search_column">
                    <v-autocomplete
                        solo
                        filled
                        label="Name"
                        v-model="searchParams.name"
                        item-value="name"
                        item-text="name"
                        :items="names"
                        :loading="isLoading"
                        :search-input.sync="name"
                        @change="getFilterData"
                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                    ></v-autocomplete>
                  </div>
                </th>
                <th >
                  <div class="">Mobile</div>
                  <div class="search_column">
                    <v-autocomplete
                        solo
                        filled
                        label="Mobile"
                        v-model="searchParams.mobile"
                        item-value="mobile"
                        item-text="mobile"
                        :items="phones"
                        :loading="isLoading"
                        :search-input.sync="phone"
                        @change="getFilterData"
                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                    ></v-autocomplete>
                  </div>
                </th>
                <th >
                  <div class="">Email</div>
                  <div class="search_column">
                    <v-autocomplete
                        solo
                        filled
                        label="Email"
                        v-model="searchParams.email"
                        item-value="email"
                        item-text="email"
                        :items="emails"
                        :loading="isLoading"
                        :search-input.sync="email"
                        @change="getFilterData"
                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                    ></v-autocomplete>
                  </div>
                </th>
                <th >
                  <div class="" style="min-width: 130px">
                    Customer Level
                  </div>
                  <div class="search_column">
                    <v-select
                        :items="trainerLevels"
                        item-text="name"
                        item-value="id"
                        label="Level"
                        v-model="searchParams.level_id"
                        solo
                        filled
                        @change="getFilterData"
                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                    >
                      <template slot="item" slot-scope="data">
                        <template
                            v-if="!(typeof data.item == 'object')"
                        >
                          <v-list-item-content
                              style="text-align: center"
                          >
                            {{ data.item }}
                          </v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-content>
                            <v-list-item-title
                                :style="`border-right: 20px solid ${data.item.color_code};`"
                            >
                              {{ data.item.name }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-select>
                  </div>
                </th>
                <th style="min-width: 200px;">
                  <div class="">
                    Training Package
                  </div>
                  <div class="search_column">
                    <v-select
                        label="Package"
                        :items="[
                                    { id: 'All', name: 'All' },
                                    ...packages,
                                  ]"
                        item-text="name"
                        item-value="id"
                        v-model="searchParams.training_package_id"
                        @change="getFilterData"
                        solo
                        filled
                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                    >
                      <template slot="item" slot-scope="data">
                        <template
                            v-if="!(typeof data.item == 'object')"
                        >
                          <v-list-item-content
                              style="text-align: center"
                          >
                            {{ data.item }}
                          </v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ data.item.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                                v-if="data.item.name != 'All'"
                            >
                              {{
                                data.item.package_type == "I"
                                    ? "Individual"
                                    : data.item.package_type == "C"
                                        ? "Couple"
                                        : "Group"
                              }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-select>
                  </div>
                </th>
                <th style="min-width: 230px">
                  <div
                      class=" cursor-pointer"
                      @click="sortColumn('remaining_sessions')"
                  >
                    Remaining Sessions
                    <v-icon class="ml-2" color="#000" small>
                      mdi-sort-{{
                        orderDir == "ASC"
                            ? "ascending"
                            : "descending"
                      }}
                    </v-icon>
                  </div>
                  <div class="search_column">
                    <v-menu
                        v-model="sessionMenu"
                        :close-on-content-click="false"
                        max-width="180"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            label="Remaining sessions"
                            solo
                            v-bind="attrs"
                            v-on="on"
                            class="q-text-field shadow-0"
                            flat
                            hide-details
                        >
                        </v-text-field>
                      </template>
                      <v-card width="180">
                        <v-card-text>
                          <v-text-field
                              dense
                              v-model="searchParams.sessions_from"
                              label="From"
                          ></v-text-field>
                          <v-text-field
                              dense
                              v-model="searchParams.sessions_to"
                              label="To"
                          ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              text
                              @click="
                                          () => {
                                            searchParams.sessions_from = null;
                                            searchParams.sessions_to = null;
                                            sessionMenu = false;
                                            getFilterData();
                                          }
                                        "
                          >Clear</v-btn
                          >
                          <v-btn
                              color="primary"
                              text
                              @click="
                                          () => {
                                            sessionMenu = false;
                                            getFilterData();
                                          }
                                        "
                          >Filter</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </div>
                </th>
                <th >
                  <div
                      class="">
                    Schedules
                  </div>
                </th>
                <th>
                  <div class="">Notes</div>
                </th>
                <th>
                  <div class="">Payment</div>
                </th>

                <th>
                  <div class="">History</div>
                </th>
                <th v-if="checkWritePermission($modules.trainers.management.slug)">
                  <div class="text-center">Edit</div>
                </th>
              </tr>
              </thead>

              <tbody>
              <tr
                  v-for="customer in customerList"
                  :key="customer.id" class="border-bottom"
              >
                <td class="">
                  <span class="text_ellipse text-neon  pointer text-base font-bold"  @click="showUserModel(customer.customer_id)">{{ customer.name }} </span>
                </td>
                <td class="">
                  {{ customer.mobile }}
                </td>
                <td class="">
                  {{ customer.email }}
                </td>
                <td class="">
                  <v-chip
                      class="ma-2"
                      dark
                      :color="customer.color_code"
                  >
                    {{ customer.customer_level || "NA" }}
                  </v-chip>
                </td>
                <td class="">
                  {{ customer.training_package }} <br />
                  ({{ customer.package_type }})
                </td>
                <td class="">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on"
                                  >{{
                                      customer.remaining_sessions
                                    }}
                                    Sessions</span
                                  >
                    </template>
                    <span>
                                  {{ customer.remaining_sessions }} sessions
                                  remaining
                                </span>
                  </v-tooltip>
                </td>
                <td class="">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn  @click="schedulePopup(customer.order_id)" v-bind="attrs" x-small text icon :disabled="customer.remaining_sessions == 0"  >
                        <ScheduleIcon />
                      </v-btn>
                    </template>

                    <span>Schedule a session</span>
                  </v-tooltip>
                </td>
                <td>
                  <div>
                    <v-btn normal small @click="openNotes(customer)"
                    >Notes</v-btn
                    >
                  </div>
                </td>
                <td class="">
                  <template>
                    <v-btn
                        @click="orderPayment(customer.order_id)"
                        :class="`d-inline-block text-truncate white--text
                                    ${
                                      customer.package_status_id == 4 ||
                                      customer.package_status_id == 21
                                        ? 'teal-color'
                                        : 'success'
                                    }`"
                        text
                        small
                    >
                      {{
                        customer.package_status_id == 4 ||
                        customer.package_status_id == 21
                            ? "Receipt"
                            : "Pay"
                      }}
                    </v-btn>
                  </template>
                </td>
                <!-- <td class="">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="openTopupForm(customer.customer_id)"
                        class="pl-2"
                        color="#066a8c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-package-up</v-icon
                      >
                    </template>
                    <span>Update Package or Add Sessions</span>
                  </v-tooltip>
                </td> -->
                <td class="">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                          @click="getScheduleHistory(customer.id)"
                          color="#141B34"
                      >mdi-history</v-icon
                      >
                    </template>
                    <span>Show customer schedule history</span>
                  </v-tooltip>
                </td>
                <td class="">
                  <v-tooltip bottom v-if="checkWritePermission($modules.trainers.management.slug)">
                    <template v-slot:activator="{ on, attrs }">
                      <SvgIcon>
                        <EditIcon
                            v-bind="attrs"
                            v-on="on"
                            @click="editCustomer(customer.order_id)"

                        />
                      </SvgIcon>

                    </template>
                    <span>Edit customer</span>
                  </v-tooltip>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>

    <v-row>
      <v-col cols="4"></v-col>
      <v-col cols="4">
        <v-pagination v-model="page" :length="totalPages" class="new-pagination" total-visible="7"></v-pagination>
      </v-col>
      <v-col class="d-flex align-center justify-end" cols="4">
        <div class="d-flex align-center justify-end text-sm gap-x-2">
                <span class="">
                  Result
                </span>
          <div style="width: 80px">
            <v-select
                v-model="perPage"
                :items="[10, 15, 25, 50]"
                :menu-props="{ bottom: true, offsetY: true }"
                class="q-autocomplete text-sm"
                flat
                hide-details
                solo
                @change="getTrainerCustomers()"
            ></v-select>
          </div>
          <span>Per Page</span>
        </div>
      </v-col>
    </v-row>
    <order-details
      :id="orderId"
      @close="orderAction"
      @paymentDone="orderAction"
    ></order-details>
    <customer-model
      ref="customerModel"
      :isPermissionCheck="checkWritePermission($modules.trainers.management.slug)"
      v-bind="userModel"
      @orderAction="orderAction"
      @close="userModel.userID = null"
    />
    <customer-form
      :customerFormDialoge="customerFormDialoge"
      @close="(customerFormDialoge = false), (customerOrder = null)"
      :trainerId="trainerId"
      :orderId="customerOrder"
      @complete="updateCustomerDetails"
    ></customer-form>
    <topup-package-form
      :trainerId="trainerId"
      :customerId="customerId"
      @close="customerId = null"
      @openReceipt="
        (data) => {
          orderId = data;
        }
      "
    ></topup-package-form>

    <schedule-session-form
      @close="scheduleOrderId = null"
      :orderId="scheduleOrderId"
      @complete="scheduleComplete"
    ></schedule-session-form>

    <scheduled-session-history
      @close="(historyBookingId = null), getTrainerCustomers()"
      :bookingId="historyBookingId"
    ></scheduled-session-history>
    <CustomerNotes
        :notes="notes"
        :notes-dialogue="notesDialogue"
        :trainer-booking-id="notesBookingId"
        :customer-id="notesCustomerId"
        @closeDialogue="closeDialogue"
        @refreshData="refreshBookings"
    />

  </div>
</template>

<script>
import constantValue from "@/utils/constants";
import OrderDetails from "@/components/Order/OrderDetails.vue";
import moment from "moment";
import CustomerModel from "../../Clients/Customer/CustomerModel";
import CustomerForm from "../CustomerForm";
import TopupPackageForm from "./TopupPackageForm";
import ScheduleSessionForm from "./ScheduleSessionForm";
import ScheduledSessionHistory from "./ScheduledSessionHistory";
import customerTraining from "@/mixins/customerTraining";
import AddIcon from "@/assets/images/misc/plus-icon.svg";
import ScheduleIcon from "@/assets/images/trainers/schedule-icon.svg";
import EditIcon from "@/assets/images/tables/edit.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import CustomerNotes from "@/components/Customer/CustomerNotes.vue";
export default {
  props: {
    trainerId: { type: Number, default: null },
    trainer: {
      type: Object,
      default: () => {},
    },
    trainerLevels: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [customerTraining],
  data() {
    return {
      constant: constantValue,
      customer: { dob: null },
      searchParams: { package_type: "all" },
      customerList: [],
      names: [],
      name: null,
      phones: [],
      phone: null,
      emails: [],
      email: null,
      isLoading: false,
      totalPages: 1,
      customerFormDialoge: false,
      page: 1,
      nationalityPieChart: {},
      agePieChart: {},
      genderPieChart: {},
      toggleTab: "logs",
      existingUser: false,
      customerId: null,
      scheduleOrderId: null,
      userModel: { userID: null, type: "details" },
      topupLogDialoge: false,
      topLogsData: [],
      customerOrder: null,
      sessionMenu: false,
      orderId: null,
      historyBookingId: null,
      packages: [],
      orderBy: "remaining_sessions",
      orderDir: "DESC",
      perPage:10,
      notesDialogue: false,
      notesBookingId:null,
      notesCustomerId:null,
      notes:[],
    };
  },
  components: {
    CustomerNotes,
    SvgIcon,
    AddIcon,ScheduleIcon,EditIcon,
    OrderDetails,
    CustomerModel,
    CustomerForm,
    TopupPackageForm,
    ScheduleSessionForm,
    ScheduledSessionHistory,
  },
  computed: {
    scheduleDate() {
      return moment(this.schedule.date).format("Do MMMM YYYY");
    },
  },
  watch: {
    trainerId: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.getPackages();
          this.getFilterData();
        }
      },
    },
    page() {
      this.getTrainerCustomers();
    },
    name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("name", val);
    },
    phone(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("mobile", val);
    },
    email(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("email", val);
    },
    level(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("level", val);
    },
  },

  methods: {
    showUserModel(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },
    getTrainerCustomers() {
      let url = this.getFilterUrl();
      this.showLoader("Loading");
      this.$http
        .get(`/venues/trainers/customers?trainer_id=${this.trainerId}${url}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.customerList = response.data.data;
            this.totalPages = response.data.total_pages;
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    addCustomer() {
      this.customer = {};
      this.selectedPackage = {};
      this.customerFormDialoge = true;
    },
    openTopupForm(customerId) {
      this.customerId = customerId;
    },
    editCustomer(orderId) {
      this.customerOrder = orderId;
      this.customerFormDialoge = true;
    },
    getFilterUrl() {
      let url = `&page=${this.page}`;
      url +=  "&per_page=" +
      (this.perPage != null ? this.perPage : 10) ;
      if (this.searchParams.name == "All") {
        this.searchParams.name = null;
      } else if (this.searchParams.name != null) {
        url += "&name=" + this.searchParams.name;
      }
      if (this.searchParams.package_type != "all") {
        url += "&package_type=" + this.searchParams.package_type;
      }

      if (this.searchParams.email == "All") {
        delete this.searchParams.email;
      } else if (this.searchParams.email != null) {
        url += "&email=" + this.searchParams.email;
      }
      if (this.searchParams.mobile == "All") {
        delete this.searchParams.mobile;
      } else if (this.searchParams.mobile != null) {
        url += "&mobile=" + this.searchParams.mobile;
      }
      if (this.searchParams.level_id == "All") {
        delete this.searchParams.level;
      } else if (this.searchParams.level_id) {
        url += "&level_id=" + this.searchParams.level_id;
      }
      if (this.searchParams.training_package_id == "All") {
        delete this.searchParams.training_package_id;
      } else if (this.searchParams.training_package_id) {
        url += "&training_package_id=" + this.searchParams.training_package_id;
      }
      if (this.searchParams.sessions_from) {
        url += "&sessions_from=" + this.searchParams.sessions_from;
      }
      if (this.searchParams.sessions_to) {
        url += "&sessions_to=" + this.searchParams.sessions_to;
      }
      url += `&order_by=${this.orderBy}&sort_order=${this.orderDir}`;

      return url;
    },
    filterSearch(type, val) {
      if (val == "All") return;
      val = val.replace(/[^\w \t@]+|@(?!\w)[^\w \t.]+|.(?!\w)/g, "");
      if (!val) return;
      this.isLoading = true;
      this.$http
        .get(`venues/trainers/customers/filters?field=${type}&search=${val}`)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.isLoading = false;
            if (type == "name") {
              this.names = response.data.data;
              this.names.unshift(val);
              this.names.unshift("All");
            } else if (type == "mobile") {
              this.phones = response.data.data;
              this.phones.unshift(val);
              this.phones.unshift("All");
            } else if (type == "email") {
              this.emails = response.data.data;
              this.emails.unshift(val);
              this.emails.unshift("All");
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFilterData() {
      this.page = 1;
      this.getTrainerCustomers();
    },
    updateCustomerDetails(data) {
      this.customerFormDialoge = false;
      if (data.status == "unpaid") this.orderId = data.id;
      this.$emit("reloadTrainer");
      this.getFilterData();
    },
    scheduleComplete() {
      this.scheduleOrderId = null;
      this.getFilterData();
      this.$emit("reloadTrainer");
    },
    orderAction() {
      this.orderId = null;
      this.getFilterData();
      this.$emit("reloadTrainer");
    },
    schedulePopup(orderId) {
      this.scheduleOrderId = orderId;
    },
    orderPayment(id) {
      this.orderId = id;
    },
    getScheduleHistory(id) {
      this.historyBookingId = id;
    },
    sortColumn(type) {
      if (type == this.orderBy) {
        this.orderDir = this.orderDir == "ASC" ? "DESC" : "ASC";
      } else {
        this.orderDir = "DESC";
      }
      this.orderBy = type;
      this.getTrainerCustomers();
    },
    closeDialogue(){
      this.notesDialogue= false;
      this.notes=[];
    },
    refreshBookings(){
      this.getNotes(this.notesBookingId)
    },
    async getNotes(customer_id){
      this.showLoader('Loading notes')
      await this.$http.get(`venues/trainers/customers/get-notes/${customer_id}`).then((response) => {
        if (response.status === 200 && response.data.status) {
          this.notes = response.data.data;
        }else{
          this.notes = [];
        }
      }).catch((error) => {
        this.errorChecker(error);
      }).finally(()=>{
        this.hideLoader()
      });
    },
    async openNotes(customer){
      console.log(customer)
      await this.getNotes(customer.id);
      this.notesBookingId = customer.id;
      this.notesCustomerId = customer.customer_id;
      this.notesDialogue = true;
    },

  },
};
</script>

<style scoped>
.v-select-list >>> .v-subheader {
  font-size: 0.95rem !important;
  font-weight: 600 !important;
  color: black;
}
</style>
