<template>
  <v-dialog v-model="topupDialog" width="600">
    <v-form ref="form">
      <v-card-title class="headline"> Add sessions </v-card-title>
      <v-card>
        <v-card-text class="form_bg">
          <v-container>
            <div class="titles">Package History</div>
            <v-card
              v-for="pkg in customerTrainingpackages"
              :key="pkg.id"
              :style="cardStyle"
              flat
              class="d-flex justify-space-between pa-3 mb-2"
            >
              <div>
                <div>Package name</div>
                <div class="subtitle-1 font-weight-black teal--text">
                  {{ pkg.name }}
                </div>
              </div>
              <div>
                <div>Price</div>
                <div class="subtitle-1 font-weight-black teal--text">
                  {{ pkg.price | toCurrency }}
                </div>
              </div>
              <div>
                <div>Remaining Sessions</div>
                <div class="subtitle-1 font-weight-black teal--text">
                  {{ pkg.remaining_sessions }} / {{ pkg.sessions }}
                </div>
              </div>
              <div>
                <div>Receipt</div>
                <v-btn small color="teal" dark @click="receipt(pkg.order_id)"
                  >View</v-btn
                >
              </div>
            </v-card>
            <!-- <div class="titles">New Package</div>
            <v-card :style="cardStyle" flat>
              <v-container>
                <v-row justify="center">
                  <v-col cols="4">
                    <v-select
                      label="Package"
                      :items="packages"
                      item-text="name"
                      item-value="id"
                      :rules="[(v) => !!v.price || 'Package is required']"
                      v-model="packageSelected"
                      return-object
                      @change="getLevels"
                      outlined
                      background-color="#fff"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Sessions"
                      v-model="packageSelected.sessions"
                      readonly
                      outlined
                      background-color="#fff"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Price"
                      v-model="packageSelected.price"
                      readonly
                      outlined
                      background-color="#fff"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="6">
                    <v-select
                      label="Trainers*"
                      v-model="registerForm.trainer_ids"
                      :rules="[(v) => !!v || 'Trainer(s) is required']"
                      :items="trainers"
                      item-text="name"
                      item-value="id"
                      outlined
                      multiple
                      background-color="#fff"
                    ></v-select>
                  </v-col>
                  <v-col sm="6">
                    <v-select
                      label="Level*"
                      v-model="registerForm.level_id"
                      :items="levels"
                      item-text="name"
                      item-value="id"
                      outlined
                      background-color="#fff"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card> -->
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2 white--text blue-color" @click="close"
            >Close</v-btn
          >
          <!-- <v-btn
            class="ma-2 white--text red-color"
            @click="topupTrainerCustomer"
            >Add package</v-btn
          > -->
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import moment from "moment";
import customerTraining from "@/mixins/customerTraining";
export default {
  props: {
    "trainer-id": { type: Number, default: null },
    "customer-id": { type: Number, default: null },
  },
  data() {
    return {
      topupDialog: false,
      customerTrainingpackages: [],
      registerForm: {},
      packageSelected: {},
    };
  },
  watch: {
    customerId: {
      immediate: true,
      handler(val) {
        if (val) {
          // this.getPackages();
          // this.getTrainers();
          this.getCustomerTrainingHistory();
          // this.registerForm = {};
          // this.packageSelected = {};
        }
      },
    },
  },
  mixins: [customerTraining],
  methods: {
    close() {
      this.topupDialog = false;
      this.$emit("close");
    },
    receipt(orderId) {
      this.$emit("openReceipt", orderId);
    },
    topupTrainerCustomer() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.registerForm.date = moment().format("YYYY-MM-DD");
      this.registerForm.customer_id = this.customerId;
      this.registerForm.training_package_id = this.packageSelected.id;
      this.$http
        .post("venues/trainers/customers/orders", this.registerForm)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess(response.data.message);
            this.receipt(response.data.data.orderId);
            this.close();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getCustomerTrainingHistory() {
      this.showLoader("Loading");
      this.$http
        .get(`venues/trainers/customers/orders/${this.customerId}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.customerTrainingpackages = response.data.data;
            this.topupDialog = true;
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>

<style>
</style>