var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","persistent":"","width":"40%","content-class":""},model:{value:(_vm.availabilityFormDialogue),callback:function ($$v) {_vm.availabilityFormDialogue=$$v},expression:"availabilityFormDialogue"}},[_c('v-form',{ref:"form"},[_c('v-card',{},[_c('v-card-text',{staticClass:"border-bottom"},[_c('div',{staticClass:"row pt-1 border-bottom"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"d-flex justify-space-between align-center mt-2"},[_c('SvgIcon',{staticClass:"text-2xl font-semibold",staticStyle:{"color":"black"},attrs:{"text":"Trainers Availability Hours"}}),_c('v-btn',{staticClass:"shadow-0",attrs:{"fab":"","x-small":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)])]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.timeSlotList),function(timeSlot,k){return _c('v-card',{key:k,staticClass:"pa-2 mt-6 mx-2",staticStyle:{"background-color":"rgba(240, 245, 249, 1)","border-radius":"8px"},attrs:{"max-height":"250px","min-height":"100px","elevation":"0"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"lg":"4","md":"12"}},[_c('label',{attrs:{"for":""}},[_vm._v("Select Days")]),_c('v-autocomplete',{staticClass:"q-autocomplete shadow-0",attrs:{"items":_vm.allWeekdays,"item-text":"name","item-value":"bit_value","multiple":"","outlined":"","background-color":"#fff","validate-on-blur":"","hide-details":"auto","dense":"","rules":[function (v) { return v.length > 0 || 'Days Applicable is required'; }]},scopedSlots:_vm._u([(_vm.allWeekdays.length == timeSlot.weekdays.length)?{key:"selection",fn:function(ref){
var index = ref.index;
return [(index == 0)?_c('span',[_vm._v("All Days")]):_vm._e()]}}:{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index == 0)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e(),(index == 1)?_c('span',[_vm._v(","+_vm._s(item.name))]):_vm._e(),(index == 2)?_c('span',[_vm._v(", ...")]):_vm._e()]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.toggle(k)}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":timeSlot.weekdays.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.getIcon(k)))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("All")])],1)],1)]},proxy:true}],null,true),model:{value:(timeSlot.weekdays),callback:function ($$v) {_vm.$set(timeSlot, "weekdays", $$v)},expression:"timeSlot.weekdays"}})],1),_c('v-col',{attrs:{"lg":"4","md":"6"}},[_c('label',{attrs:{"for":""}},[_vm._v("Start Time")]),_c('v-select',{staticClass:"q-autocomplete shadow-0",attrs:{"items":_vm.timings.slice(0, _vm.timings.length - 1),"item-text":"text","item-value":"value","rules":[function (v) { return !!v || 'From time is required'; }],"required":"","outlined":"","menu-props":{ bottom: true, offsetY: true },"background-color":"#fff","hide-details":"auto","dense":""},model:{value:(timeSlot.start_time),callback:function ($$v) {_vm.$set(timeSlot, "start_time", $$v)},expression:"timeSlot.start_time"}})],1),_c('v-col',{attrs:{"lg":"4","md":"6"}},[_c('label',{attrs:{"for":""}},[_vm._v("End Time")]),_c('v-select',{staticClass:"q-autocomplete shadow-0",attrs:{"items":_vm.timings.slice(1),"item-text":"text","item-value":"value","rules":[function (v) { return !!v || 'To time is required'; }],"required":"","outlined":"","menu-props":{ bottom: true, offsetY: true },"background-color":"#fff","hide-details":"auto","dense":""},model:{value:(timeSlot.end_time),callback:function ($$v) {_vm.$set(timeSlot, "end_time", $$v)},expression:"timeSlot.end_time"}})],1),(_vm.timeSlotList.length > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","x-small":"","absolute":"","top":"","right":"","text":""},on:{"click":function($event){return _vm.deleteTimingTemplate(k)}}},'v-btn',attrs,false),on),[_c('DeleteIcon')],1)]}}],null,true)},[_vm._v(" Delete ")]):_vm._e()],1)],1)}),1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-underline font-semibold text-base ml-2",staticStyle:{"cursor":"pointer","color":"rgba(79, 174, 175, 1)"},on:{"click":function($event){return _vm.timeSlotList.push({
                  weekdays: [],
                  start_time: null,
                  end_time: null,
                })}}},[_vm._v(" + Add New ")])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"ma-2 ",attrs:{"text":""},on:{"click":_vm.close}},[_vm._v("Close")]),_c('v-btn',{staticClass:"ma-2 white--text blue-color shadow-0",on:{"click":_vm.addOrUpdateTrainerCustomer}},[_vm._v("Save")])],1)],1)],1),_c('confirm-model',_vm._b({on:{"confirm":_vm.confirmActions,"close":function($event){_vm.confirmModel.id = null}}},'confirm-model',_vm.confirmModel,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }