<template>
  <v-container fluid>
    <v-row class=" p-y-6">
      <v-col lg="3" sm="12">
        <BackButton :handler="goBack"/>
      </v-col>
      <v-col lg="6" sm="12">


        <v-row dense  no-gutters justify="center" >

          <v-col cols="12" class="trainer_container" >
            <div class="d-flex gap-x-5 justify-center align-center">
              <div style="width: 165px; height: 165px" >
                <view-image  :image="trainer.profile_image" class="rounded-lg " :height="165" :width="165" defaultImage="user" />
                <div class="d-flex tags gap-x-3 mt-2  align-center">
                  <v-btn v-for="(service, index) in trainer.venue_services" :key="index"
                         depressed
                         small class="text-capitalize text-blue font-medium"
                  >{{getVenueServiceName(service.venue_service_id)}}</v-btn>
                </div>
              </div>


              <div class="d-flex justify-start gap-x-6 ml-2">
                <div >
                  <h3 class="text-xl m-b-4">
                    {{ trainer.first_name }} {{ trainer.last_name || "" }}
                  </h3>
                  <p style="line-height: 1px" >{{ trainer.designation }}</p>


                  <div class="d-block d-lg-flex gap-x-8 mt-6">
                    <SvgIcon class="gap-x-2 m-b-4 font-medium ">
                      <template v-slot:icon>
                        <SMSIcon/>
                      </template>
                      {{ trainer.email }}
                    </SvgIcon>
                    <SvgIcon class="gap-x-2 m-b-4 font-medium">
                      <template v-slot:icon>
                        <CallIcon/>
                      </template>
                      {{ trainer.mobile }}
                    </SvgIcon>
                  </div>
                  <div class="d-flex gap-x-16 mt-2 ">
                    <div class="stats_data">
                      <p class="text-dark-gray text-base font-normal">Students</p>
                      <p  class="text-blue font-semibold">{{ Number(trainer.students) }}</p>
                    </div>
                    <div class="stats_data" v-if="checkReadPermission($modules.trainers.sales.slug)">
                      <p class="text-dark-gray text-base font-normal">Sales</p>
                      <p  class="text-blue font-semibold">{{ Number(trainer.total_sales) | toCurrency}}</p>
                    </div>
                    <div class="stats_data"  v-if="checkReadPermission($modules.trainers.revenue.slug)">
                      <p class="text-dark-gray text-base font-normal text-no-wrap">Earned Revenue</p>
                      <p  class="text-blue font-semibold">{{ Number(trainer.sales) | toCurrency }}</p>
                    </div>
                    <div class="stats_data"    v-if="checkReadPermission($modules.trainers.commission.slug)">
                      <p class="text-dark-gray text-base font-normal">Commission</p>
                      <p  class="text-blue font-semibold"> {{ Number(trainer.commission) | toCurrency}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </v-col>


          <v-col cols="12">

          </v-col>
        </v-row>


      </v-col>
      <v-col lg="3" sm="12" class="mt-sm-3  d-sm-flex">
        <v-btn
            v-if="checkWritePermission($modules.trainers.management.slug)"
            class="ma-2  text_capitalize "
            @click="
            confirmDelete({
              id: trainerId,
              status_id: trainer.status_id,
              flag: true,
            })
          "
            :color="trainer.status_id == 1?'red':''"
            outlined
        >
          <span class="ml-1">{{ trainer.status_id == 1 ? "Deactivate" : "Activate" }}</span>
        </v-btn>
        <v-btn
            v-if="checkWritePermission($modules.trainers.management.slug)"
            style="background-color: rgba(17, 42, 70, 0.1) ; color:#112A46 "
            class="ma-2  text_capitalize "
            @click="trainerDetails(trainerId,'view',true)"
            outlined
        > <EditBtnIcon/>
          <span class="ml-1">Edit</span>   </v-btn>
      </v-col>
    </v-row>


    <div class="row mt-5">
      <div class="col-12">
        <v-card class="shadow-0">
          <v-card-actions class="text-center " style="border-top: 1px solid #F1F1F1">
            <v-tabs
                v-model="tab"
                background-color="transparent"
                centered
                class="q-tabs-secondary shadow-0  border-bottom"
                light
                slider-color="transparent"
            >
              <v-tab active-class="active_tab_stroke_icon"
                     class=" border-bottom "
                     href="#packages" @click="showCalender = false">
                <SvgIcon text="Packages">
                  <template v-slot:icon>
                    <PackagesIcon class="svg-stroke-neon"/>
                  </template>
                </SvgIcon>
              </v-tab>
              <v-tab active-class="active_tab_fill_icon"
                     class="d-flex border-bottom "

                     href="#details" @click="showCalender = false">
                <SvgIcon text="Students">
                  <template v-slot:icon>
                    <StudentIcon class="svg-stroke-neon"/>
                  </template>
                </SvgIcon>
              </v-tab>
              <v-tab v-if="checkWritePermission($modules.trainers.sales.slug)"
                     active-class="active_tab_stroke_icon"
                     class="border-bottom " href="#sales"
                     @click="showCalender = false">
                <SvgIcon text="Sales">
                  <template v-slot:icon>
                    <SalesIcon/>
                  </template>
                </SvgIcon>
              </v-tab>
              <v-tab active-class="active_tab_stroke_icon"
                     class=" border-bottom "
                     href="#analysis" @click="showCalender = false">
                <SvgIcon text="Customer Analysis">
                  <template v-slot:icon>
                    <AnalyticsIcon/>
                  </template>
                </SvgIcon>
              </v-tab>
              <v-tab
                  active-class="active_tab_fill_icon"
                  class="border-bottom " href="#trainer_calender" @click="showCalender = true">
                <SvgIcon text="Trainer Schedules">
                  <template v-slot:icon>
                    <CalenderIcon/>
                  </template>
                </SvgIcon>

              </v-tab>
              <v-tab
                  v-if="checkReadPermission($modules.trainers.availability.slug)"
                  active-class="active_tab_stroke_icon"
                  class="border-bottom"
                  href="#trainer_availability" @click="showCalender = false"
              >
                <SvgIcon text="Availability">
                  <template v-slot:icon>
                    <AvailabilityIcon/>
                  </template>
                </SvgIcon>

              </v-tab>
            </v-tabs>
          </v-card-actions>
          <v-card-text>
            <div class="row">
              <v-tabs-items v-model="tab">
                <v-tab-item class="p-4" value="packages">
                  <div class="row">
                    <div class="col-12 d-flex justify-space-between">
                      <v-autocomplete
                          v-model="packageServices"
                          :items="venueServices"
                          background-color="#fff"
                          class="q-autocomplete shadow-0"
                          dense
                          height="44"
                          hide-details="auto"
                          item-text="name"
                          item-value="venue_service_id"

                          multiple
                          outlined
                          placeholder="Select Service"
                          style="max-width: 185px "
                          @change="filterPackages"
                      >

                      </v-autocomplete>

                      <v-btn
                          v-if="trainer.training_services.length < 3
                                &&  checkWritePermission($modules.trainers.management.slug) && trainer.status_id == 1"
                          class="white--text blue-color"
                          color=" darken-1"
                          height="44"
                          text
                          @click="addTrainerService"
                      >
                        <AddIcon/>
                        <span class="ml-1">Add Package</span>
                      </v-btn>

                    </div>
                  </div>


                  <div v-if="trainer.venue_services && trainer.venue_services.length > 0" class="row">
                    <div v-for="(trainerService, ts) in trainer.filtered_training_services" :key="`tr_pc_${ts}`"
                         class="col-6 ">
                      <trainer-package v-if="trainerService.name" :key="ts"
                                       :indexKey="ts" :trainer-service="trainerService" :writePermission="checkWritePermission($modules.trainers.management.slug)"
                                       @deleteTrainerService="deleteTrainerService"
                                       @editPackageCategory="editPackageCategory"/>
                    </div>


                    <div v-for="(trainerService,ts) in trainer.filtered_global_packages"
                         :key="`g_${trainerService.id}`"
                         class="col-6 "
                    >
                      <trainer-package :key="ts" :indexKey="ts"
                                       :isGlobal="true" :trainer-service="trainerService" :writePermission="checkWritePermission($modules.trainers.management.slug)"
                                       @deleteTrainerService="deleteTrainerService"
                                       @editPackageCategory="editPackageCategory"/>
                    </div>
                  </div>


                </v-tab-item>
                <v-tab-item class="p-4" value="details">
                  <trainer-customer-section
                      :trainer="trainer"
                      :trainerId="trainerId"
                      :trainerLevels="levels"
                      @reloadTrainer="trainerDetails(trainerId, 'view')"
                  />
                </v-tab-item>
                <v-tab-item value="sales">
                  <trainer-sales-graph :exportPermission="
              checkExportPermission($modules.trainers.management.slug)
            "
                                       :trainerId="salesTrainerId"
                                       class="p-4 mt-8"
                  ></trainer-sales-graph>
                </v-tab-item>
                <v-tab-item class="p-4 mt-4" value="analysis">
                  <trainer-customer-chart
                      :exportPermission="
              checkExportPermission($modules.trainers.management.slug)
            "
                      :params="params"
                      :reload="pieReload"
                      :showProductType="false"
                      stackType="all"
                  ></trainer-customer-chart>
                </v-tab-item>
                <v-tab-item class="p-4 mt-8" value="trainer_calender">
                  <trainer-calender v-if="showCalender" :trainerId="trainerId">
                  </trainer-calender>
                </v-tab-item>
                <v-tab-item class="p-4" value="trainer_availability">
                  <trainer-availability
                      :trainer="trainer"
                      :trainerId="trainerId"
                      :trainerLevels="levels">
                  </trainer-availability>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-card-text>

        </v-card>
      </div>
    </div>



    <v-dialog
        v-model="trainerPackageDialog"
        @input="cancelPackageEdit"
        scrollable
        width="40%"
    >
      <v-form ref="form" v-model="valid" >
        <v-card>
          <v-card-text class="border-bottom">
            <div class="row  border-bottom pt-1">
              <div class="col-md-12">
                <div class="d-flex justify-space-between align-center mt-2">
                  <SvgIcon class="text-2xl font-semibold" text="Trainers Packages" style="color: black" >
                  </SvgIcon>
                  <v-btn  fab x-small class="shadow-0" @click="cancelPackageEdit">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <div v-if="trainer.training_services[index] != null" class="row" >
              <v-col md="6">
                <label class="text-dark-gray font-medium text-xs">Select Service</label>

                <v-autocomplete
                    v-model="
                        trainer.training_services[index].venue_service_id
                      "
                    :items="venueServices"

                    item-text="name"
                    item-value="venue_service_id"
                    outlined

                    required
                    dense
                    hide-details="auto"
                    class="q-autocomplete shadow-0"
                    validate-on-blur

                    :rules="[(v) => !!v || 'Service is required']"
                >
                </v-autocomplete>
              </v-col>
              <v-col md="6">
                <label class="text-dark-gray font-medium text-xs">Tax Type</label>

                <v-autocomplete
                    @change="taxChange(trainer.training_services[index])"
                    v-model="trainer.training_services[index].tax_type_id"
                    :items="taxTypes"

                    item-text="text"
                    item-value="value"
                    outlined

                    required
                    :rules="[(v) => !!v || 'Tax type is required']"
                    validate-on-blur

                    dense
                    hide-details="auto"
                    class="q-autocomplete shadow-0"
                >
                </v-autocomplete>
              </v-col>
            </div>
            <div v-if="trainer.training_services[index] != null" class="row">
              <div class="col-md-12">
                <v-card
                    v-for="(training_package, tp) in trainer.training_services[
                    index
                  ].training_packages"
                    :key="tp"
                    outlined
                    :class="{ 'mb-7': tp !== trainer.training_services[
                    index
                  ].training_packages.length - 1 }"
                >
                  <div class="pa-4">
                    <v-row>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              v-if="
                              trainer.training_services[index].training_packages
                                .length > 1
                            "
                              absolute
                              top
                              right
                              v-bind="attrs"
                              v-on="on"
                              color="red"
                              class="mt-1"
                              @click="warningPackageRemove(index, tp)"
                              fab
                              x-small
                              text
                          >
                            <DeleteIcon/>
                          </v-btn>
                        </template>
                        Delete
                      </v-tooltip>
                      <v-col md="6">
                        <label class="text-dark-gray font-medium text-xs">Name</label>
                        <v-text-field
                            required
                            v-model="training_package.name"
                            outlined
                            background-color="#fff"
                            :rules="[(v) => !!v || 'Name is required']"
                            validate-on-blur

                            dense
                            hide-details="auto"
                            class="q-text-field shadow-0"
                        ></v-text-field>
                      </v-col>
                      <v-col md="6">
                        <label class="text-dark-gray font-medium text-xs">Duration</label>
                        <v-select

                            required
                            v-model="training_package.duration"
                            hint="Session duration (default 1 hr)"
                            :items="getMinBookingTimes(index)"
                            outlined
                            :menu-props="{ bottom: true, offsetY: true }"

                            dense
                            hide-details="auto"
                            class="q-autocomplete shadow-0"
                        ></v-select>
                      </v-col>
                      <v-col md="6">
                        <label class="text-dark-gray font-medium text-xs">Sessions*</label>

                        <v-text-field
                            required
                            :rules="[
                            (v) => !!v || 'Session is required',
                            (v) => !isNaN(v) || 'Session must be Number',
                          ]"
                            v-model="training_package.sessions"
                            outlined
                            dense
                            hide-details="auto"
                            class="q-text-field shadow-0"
                            validate-on-blur

                        ></v-text-field>
                      </v-col>
                      <v-col md="6">
                        <label for="" class="text-dark-gray font-medium text-xs">Type*</label>
                        <v-select

                            required
                            v-model="training_package.package_type"
                            outlined
                            :menu-props="{ bottom: true, offsetY: true }"
                            :items="packageTypes"
                            @change="setPackagePeople(index, tp)"
                            item-text="name"
                            item-value="type"
                            :rules="[(v) => !!v || 'Type is required']"
                            validate-on-blur

                            dense
                            hide-details="auto"
                            class="q-autocomplete shadow-0"
                        ></v-select>
                      </v-col>
                      <v-col md="6" v-if="training_package.package_type == 'G'">
                        <label for="" class="text-dark-gray font-medium text-xs">People</label>
                        <v-text-field
                            required
                            v-model="training_package.people"
                            :rules="[
                            (v) => !!v || 'No persons is required',
                            (v) => !isNaN(v) || 'Price must be Number',
                          ]"
                            outlined
                            dense
                            hide-details="auto"
                            class="q-text-field shadow-0"
                            validate-on-blur

                        ></v-text-field>
                      </v-col>
                      <v-col md="6">
                        <label for="" class="text-dark-gray font-medium text-xs">Price (Pre Tax)*</label>
                        <v-text-field
                            @change="
                            calculateTaxVariation(
                              $event,
                              trainer.training_services[index],
                              training_package,
                              'pre'
                            )
                          "
                            required
                            v-model="training_package.pre_tax_price"
                            outlined
                            :prefix="currencyCode"
                            dense
                            hide-details="auto"
                            class="q-text-field shadow-0"
                        ></v-text-field>
                      </v-col>
                      <v-col md="6">
                        <label for="" class="text-dark-gray font-medium text-xs">Price (Post Tax)*</label>
                        <v-text-field

                            @change="
                            calculateTaxVariation(
                              $event,
                              trainer.training_services[index],
                              training_package,
                              'post'
                            )
                          "
                            required
                            v-model="training_package.total_price"
                            outlined
                            :prefix="currencyCode"

                            dense
                            hide-details="auto"
                            class="q-text-field shadow-0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </div>
              <div class="col-md-12">
                <span class="text-underline font-semibold text-base ml-2" style="cursor: pointer ; color: rgba(79, 174, 175, 1)"   @click="addTrainerPackage(index)"> + Add New </span>
              </div>
            </div>

          </v-card-text>

          <v-card-actions >
            <v-spacer></v-spacer>
            <v-btn
                class="ma-2 "
                text
                @click="cancelPackageEdit"
            >Close</v-btn
            >
            <v-btn
                class="ma-2 white--text blue-color"
                @click="addOrEditTrainer($event, true)"
            >{{
                trainer.training_services[index] &&
                trainer.training_services[index].id != null
                    ? "Update"
                    : "Save"
              }}</v-btn
            >
          </v-card-actions>
        </v-card>

      </v-form>
    </v-dialog>

    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>

    <TrainerAddEditDialogue :trainer="trainer" :addTrainerDialog="addTrainerDialog" :editFlag="editFlag" :venueServices="venueServices" v-if="addTrainerDialog" @cancelPersonalTraining="cancelPersonalTraining"/>
  </v-container>
</template>

<script>
import TrainerCustomerSection from "./TrainerTabs/TrainerCustomerSection";
import TrainerSalesGraph from "./TrainerTabs/TrainerSalesGraph";
import TrainerCustomerChart from "@/components/Chart/CustomerPieCharts";
import trainer from "@/mixins/trainer";
import TrainerCalender from "./TrainerTabs/TrainerCalender.vue";
import TrainerAvailability from "./TrainerTabs/TrainerAvailability.vue";
import SMSIcon from "@/assets/images/trainers/email-icon.svg";
import CallIcon from "@/assets/images/trainers/call-icon.svg";
import PackagesIcon from "@/assets/images/trainers/packages.svg";
import StudentIcon from "@/assets/images/trainers/student-card.svg";
import CalenderIcon from "@/assets/images/trainers/calendar-icon.svg";
import AnalyticsIcon from '@/assets/images/trainers/analytics-icon.svg';
import AvailabilityIcon from '@/assets/images/trainers/timer-02.svg';
import SalesIcon from '@/assets/images/trainers/analytics-up1.svg';
import EditBtnIcon from "@/assets/images/misc/pencil-icon.svg";

import TrainerPackage from "@/views/Trainers/TrainerTabs/TrainerPackage.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import DeleteIcon from "@/assets/images/misc/delete-bg-icon.svg";
import TrainerAddEditDialogue from "@/views/Trainers/TrainerAddEditDialogue.vue";
import BackButton from "@/components/Common/BackButton.vue";
import AddIcon from "@/assets/images/misc/plus-icon.svg";

export default {
  components: {
    AddIcon,
    BackButton,
    TrainerAddEditDialogue,
    DeleteIcon,
    SvgIcon,
    TrainerPackage,
    EditBtnIcon,
    CallIcon,
    SMSIcon,
    PackagesIcon,
    CalenderIcon,
    AvailabilityIcon,
    AnalyticsIcon,
    SalesIcon,
    TrainerSalesGraph,
    TrainerCustomerSection,
    TrainerCustomerChart,
    TrainerCalender,
    TrainerAvailability,
    StudentIcon
  },
  mixins: [trainer],
  data() {
    return {
      addTrainerDialog:false,
      editFlag:false,
      tab: "packages",
      trainerId: null,
      packageServices: [],
      image: null,
      trainer: {
        training_services: [{ venue_service_id: null }],
        deleted_packages: [],
        deleted_services: [],
        filtered_training_services : [],
        filtered_global_packages : []
      },


      trainerPackageDialog: false,
      valid: false,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      index: 0,
      params: { venue_service_ids: [], product_ids: [], product_type_ids: [5] },
      packageTypes: [
        { type: "I", name: "Individual" },
        { type: "C", name: "Couple" },
        { type: "G", name: "Group" },
      ],
      salesTrainerId: null,
      levels: [],
      pieReload: true,
      showCalender: false,
      // timeSlotList: [],
    };
  },
  created() {
    if (typeof this.$route.params.data != "undefined") {
      this.trainerId = parseInt(atob(this.$route.params.data));
      this.trainerDetails(this.trainerId, "view");
      // this.loadAvailability();
    }
  },
  watch: {
    tab: {
      immediate: true,
      handler(val) {
        if (val == "sales") {
          this.salesTrainerId = this.trainerId;
        } else {
          this.salesTrainerId = null;
        }
        if (val == "analysis") {
          this.pieReload = true;
        } else {
          this.pieReload = false;
        }
      },
    },


    trainer:{
      immediate: true,
      handler(new_val) {
        this.trainer.filtered_training_services = new_val.training_services
        this.trainer.filtered_global_packages = new_val.global_packages
      },
      deep:true
    },

  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");

    }
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getTaxTypes.status == false) {
      this.$store.dispatch("loadTaxTypes");
    }

    // this.getTrainerPackages()
  },
  computed: {
    countries() {
      return this.$store.getters.getCountries.data;
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },


    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
  },
  methods: {

    getVenueServiceName(id){
      let venue_service = this.venueServices.find((service)=>{return service.venue_service_id == id});
      return venue_service ? venue_service.name : null;
    },
    goBack(){
      this.$router.push({name: "Trainers"})
    },

    cancelPersonalTraining(reload=false){
      this.addTrainerDialog  =false;
      this.editFlag  =false;
      if(reload){
        this.trainerDetails(this.trainerId,'view');
      }
    },

    filterPackages(){
      const filteredData = this.trainer.training_services.filter(item => this.packageServices.includes(item.venue_service_id));
      const filteredDataGlobal = this.trainer.global_packages.filter(item => this.packageServices.includes(item.venue_service_id));
      this.trainer.filtered_training_services = filteredData;
      this.trainer.filtered_global_packages = filteredDataGlobal;
    },
    editPackageCategory(index) {
      this.index = index;
      this.trainerPackageDialog = true;
    },
    cancelPackageEdit() {
      this.trainerDetails(this.trainerId, "view");
      this.trainerPackageDialog = false;
    },
    gotoTrainer() {
      this.$router.push({
        name: "Trainers",
      });
    },


    getTrainerPackages(){
      this.$http
          .get(`venues/trainers/packages`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              console.log(data)
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    getPackages() {
      this.$http
          .get(
              `venues/trainers/packages${
                  this.trainerId != null ? "?trainer_id=" + this.trainerId : ""
              }`
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              if (data.length) {
                let productIds = [];
                data.forEach((product) => {
                  productIds.push(product.product_id);
                });
                this.params.product_ids = productIds;
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    getMinBookingTimes(index) {
      let duration = [];
      let venueServiceId = this.trainer.training_services[index]
          .venue_service_id;
      const venueService = this.venueServices.find(
          (item) => item.venue_service_id == venueServiceId
      );
      const timeIncrement = venueService ? venueService.time_increment : null;
      if (timeIncrement) {
        let hour = 0;
        let index = 0;
        while (hour < 10) {
          index = index + 1;
          let time = timeIncrement * index;
          hour = parseInt(time / 60);
          let min =
              time / 60 - hour > 0 ? Math.round((time / 60 - hour) * 60) : 0;
          let text = hour > 0 ? `${hour} Hour ` : "";
          text += min > 0 ? `${min} Min` : "";
          duration.push({ value: time, text: text });
        }
      }
      return duration;
    },

    calculateTaxVariation(amount, training_service, training_package, type) {
      let taxTypeId = training_service.tax_type_id;
      let taxPercentage = 0;
      if (taxTypeId) {
        taxPercentage = this.taxTypes.find((item) => item.value == taxTypeId)
            .percentage;
      }
      const priceData = this.getTaxVariation(type, taxPercentage, amount);
      training_package.price = priceData.price;
      training_package.pre_tax_price = priceData.price.toFixed(2);
      training_package.total_price = priceData.total_price;
      this.$forceUpdate();
    },

    taxChange(training_service) {
      training_service.training_packages.forEach((training_package) => {
        if (training_package.price) {
          this.calculateTaxVariation(
              training_package.price,
              training_service,
              training_package,
              "pre"
          );
        } else if (training_package.total_price) {
          this.calculateTaxVariation(
              training_package.total_price,
              training_service,
              training_package,
              "post"
          );
        }
      });
    },

    viewStudents(data) {
      console.log("vs", data);
    },
    // loadAvailability() {
    //   this.$http
    //     .get(
    //       `venues/trainers/availability${
    //         this.trainerId != null ? "/" + this.trainerId : ""
    //       }`
    //     )
    //     .then((response) => {
    //       if (response.status == 200) this.timeSlotList = response.data.data;
    //     })
    //     .catch((error) => {
    //       this.errorChecker(error);
    //     });
    // },
  },
};
</script>

<style scoped lang="scss">
.tab_active {
  background-color: #fff;
}


.stats_data{
  word-wrap: break-word;
  p{
    margin: 0;
    padding: 0;
  }
}



</style>
