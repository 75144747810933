<template>
  <div>


    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <div class="md-card md-theme-default mt-8 shadow rounded-5 align-center">
          <div class="md-card-content md-card-table">
            <div>
              <div class="d-flex justify-space-between align-center ">
                <SvgIcon class="text-2xl font-semibold" text="Time Slots" >
                </SvgIcon>

                <v-btn
                    v-if="
                            checkWritePermission(
                              $modules.trainers.availability.slug
                            ) && trainer.status_id == 1
                          "
                    @click="addAvailability"
                    class="teal-color shadow-0"
                    dark
                >
                  <ConfigIcon/>
                  <span class="ml-1">Configuration</span>
                </v-btn>
              </div>
              <div class="table-responsive mt-4">
                <table class="table border-collapse text-center">
                  <thead>
                  <tr class="opacity-70 tr-neon tr-rounded text-center">
                    <td>Days</td>
                    <td>Start Time</td>
                    <td>End Time</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr  v-for="timeSlots in timeSlotList"
                       :key="timeSlots.id">
                    <td>{{
                        getWeekdays(timeSlots.weekdays)
                      }}</td>

                    <td>
                      {{ formatTime(timeSlots.start_time) }}
                    </td>
                    <td>
                      {{
                        formatTime(timeSlots.end_time)
                      }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>


    <availability-form-multiple
        :availabilityFormDialogue="availabilityFormDialogue"
        @close="availabilityFormDialogue = false"
        :trainerId="trainerId"
        :editId="editId"
        :aval="aval"
        @loadAvail="loadAvail"
    ></availability-form-multiple>
    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>
  </div>
</template>

<script>
import moment from "moment";
import AvailabilityFormMultiple from "@/views/Trainers/AvailabilityFormMultiple.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ConfigIcon from "@/assets/images/misc/cog_icon.svg";

export default {
  props: {
    trainerId: { type: Number, default: null },
    trainer: {
      type: Object,
      default: () => {},
    },
    trainerLevels: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ConfigIcon,
    SvgIcon,
    AvailabilityFormMultiple,
  },
  created() {
    this.loadAvailability();
  },
  watch: {
    page() {
      this.loadAvailability();
    },
  },
  data() {
    return {
      confirmModel: {},
      availabilityFormDialogue: false,
      editAvailability: false,
      editId: null,
      isLoading: false,
      totalPages: 1,
      page: 1,
      aval: {},
      orderBy: "id",
      orderDir: "DESC",
      timeSlotList: {},
    };
  },

  mounted() {
    this.$store.dispatch("loadWeekdays");
  },

  methods: {
    formatTime(input) {
      return moment(input, "HH:mm:ss").format("hh:mm a");
    },
    getWeekdays(days) {
      let dayName = "";
      days.forEach((element) => {
        let findValue = this.$store.getters.getWeekdays.data.find(
          (y) => y.bit_value == element
        );
        if (findValue) {
          dayName = dayName.concat(findValue.name.substring(0, 3), ",");
        }
      });

      return dayName.slice(0, -1);
    },
    showTimeSlots(id) {
      console.log(id);
    },
    editTimeSlot(timeS) {
      this.editId = timeS.id;
      this.aval = timeS;
      this.availabilityFormDialogue = true;
    },
    addAvailability() {
      this.editId = null;
      this.aval = null;
      this.availabilityFormDialogue = true;
      console.log("add button clicked");
    },
    deleteTimeSlot(id) {
      console.log(id);
    },
    loadAvail() {
      this.loadAvailability();
      // this.$emit("loadAvaila");
    },
    getFilterUrl() {
      let url = `?page=${this.page}`;
      url += `&order_by=${this.orderBy}&sort_order=${this.orderDir}`;

      return url;
    },
    loadAvailability() {
      let url = this.getFilterUrl();
      this.$http
        .get(
          `venues/trainers/availability${
            this.trainerId != null ? "/" + this.trainerId : ""
          }${url}`
        )
        .then((response) => {
          if (response.status == 200) {
            this.timeSlotList = response.data.data;
            this.totalPages = response.data.total_pages;
            console.log("total pages " + response.data.total_pages);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    deleteAvailability(data) {
      this.confirmModel = {
        id: data.id,
        title: "Do you want to remove this Availability timing?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "delete_availability",
        data: { id: data.id, name: data.id },
      };
    },
    confirmActions(data) {
      if (data.type == "delete_availability") {
        this.showLoader("Loading");
        this.$http
          .post(
            `venues/trainers/availability/${this.trainerId}` +
              (data.id ? "/delete-availability/" + data.id : ""),
            {},
            {
              headers: {
                "Content-Type":
                  "multipart/form-data; boundary=${form._boundary}",
              },
            }
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.showSuccess("Removed successfully.");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });

        this.hideLoader();
        this.loadAvailability();
        // this.$emit("loadAvaila");
        // this.loadAvailability(this.trainerId);
      }
    },
  },
};
</script>

<style scoped>

.button_navigation {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.button_navigation .v-item--active {
  background-color: #062b48 !important;
  color: #fff !important;
}
.v-select-list >>> .v-subheader {
  font-size: 0.95rem !important;
  font-weight: 600 !important;
  color: black;
}
</style>
