<template>
  <v-card style="background-color: rgba(240, 245, 249, 1)" class="shadow-0 rounded-5">
    <div class="d-flex justify-space-between pt-4">
      <p  class="text-2xl font-semibold pl-4 " style="margin: 0">{{trainerService.name}} <sup v-if="!isGlobal"><StarIcon/></sup> </p>
      <v-spacer/>


      <v-menu v-if="!isGlobal && writePermission"
          bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              x-small
              rounded
              class="shadow-0 d-flex mr-4"
              color="white"
          >
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="()=>{this.$emit('editPackageCategory',indexKey)}" >
            <v-list-item-icon>
              <v-icon small>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Edit</v-list-item-title>
          </v-list-item>
          <v-list-item
              v-if="trainerService.length > 1"
              @click="()=>{this.$emit('deleteTrainerService',indexKey,1)}   "
          >
            <v-list-item-icon>
              <v-icon small>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-card-text :class="isGlobal ? 'mt-3':''" >
      <v-simple-table class="table p-4 table-bordered overflow-y-auto" style="background-color: #FFFFFF; border-radius: 12px ; height: 250px;">
        <thead>
        <tr class="">
          <td>Package Name</td>
          <td>Session</td>
          <td>Duration</td>
          <td>Type</td>
          <td>Price (Inc Vat)</td>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(trainerPackage,
                    tp) in trainerService.training_packages"
            :key="tp"
        >
          <td>{{ trainerPackage.name }}</td>
          <td>{{ trainerPackage.sessions }}</td>
          <td>{{ trainerPackage.duration }} mins</td>
          <td>
            {{
              trainerPackage.package_type == "I"
                  ? "Individual"
                  : trainerPackage.package_type == "C"
                      ? "Couple"
                      : "Group"
            }}
          </td>
          <td>{{ trainerPackage.total_price | toCurrency }}</td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<script>

import store from "@/store";
import StarIcon from '@/assets/images/trainers/star.svg';

export default {
  components:{StarIcon},
  computed: {
    store() {
      return store
    }
  },
  props: {
    trainerService: { type: Object, default: null },
    indexKey: { type: Number, default: 0 },
    isGlobal: { type: Boolean, default: false },
    writePermission:{type: Boolean, default: false}

  },
  methods: {


  },
};
</script>

<style scoped>
.table th {
  font-size: 1rem !important;
  height: 48px;
  border-bottom: 0 !important;
  font-weight: 500 !important;
  opacity: 1 !important;
}
.table td {
  font-size: 1rem !important;
  height: 48px;
  padding: 0 16px;
  font-weight: 400;
  border-bottom: thin solid rgba(0, 0, 0, 0.05) !important;
  opacity: 1 !important;
}
</style>
