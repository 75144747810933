<template>
  <v-dialog v-model="sessionLogDialog" scrollable width="900" persistent>
    <v-card>
      <v-card-title class="headline"> Session History </v-card-title>
      <v-card-text>
        <v-container>
          <v-row no-gutters style="margin-top: -20px">
            <table class="history_table">
              <thead>
                <th>Timestamp</th>
                <th>Date</th>
                <th>Start time</th>
                <th>End Time</th>
                <th>Sessions Scheduled</th>
                <th>Earned Revenue</th>
                <th>Attendance</th>
                <th>Status</th>
              </thead>

              <tr
                v-for="session in sessionLogs"
                :key="session.id"
                class="text-center"
              >
                <td>{{ session.created_at | timeStamp }}</td>
                <td>{{ session.date | dateformat }}</td>
                <td>{{ session.start_time | timeFormat }}</td>
                <td>{{ session.end_time | timeFormat }}</td>
                <td>{{ session.scheduled_sessions }}</td>
                <td>
                  {{ session.customer_trainer_revenue | toCurrency }}
                  <br />(Total: {{ session.total_revenue | toCurrency }})
                </td>
                <td>
                  <v-menu offset-y v-if="session.status_id == 1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :color="
                          session.is_present == 0
                            ? 'default'
                            : session.is_present == 1
                            ? 'success'
                            : 'error'
                        "
                        dark
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{
                          session.is_present == 0
                            ? "Attendance"
                            : session.is_present == 1
                            ? "Attended"
                            : "No show"
                        }}
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        @click="
                          confirmAttendance({
                            training_session_id: session.training_session_id,
                            operation: 'present',
                          })
                        "
                      >
                        <v-list-item-title>Mark as attended</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="
                          confirmAttendance({
                            training_session_id: session.training_session_id,
                            operation: 'absent',
                          })
                        "
                      >
                        <v-list-item-title>Mark no show</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <span v-else>NA</span>
                </td>
                <td>
                  <v-btn
                    small
                    class="yellow-color"
                    dark
                    v-if="session.status_id == 1"
                    @click="
                      confirmRefund({
                        training_session_id: session.training_session_id,
                        operation: 'refund',
                      })
                    "
                  >
                    Refund Session
                  </v-btn>
                  <span v-else>Refunded</span>
                </td>
              </tr>
            </table>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color=" darken-1"
          class="ma-2 white--text blue-color"
          text
          @click="close"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      sessionLogDialog: false,
      sessionLogs: [],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
    };
  },
  props: {
    "booking-id": { type: Number, default: null },
  },
  watch: {
    bookingId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getScheduleSessionHistory();
        }
      },
    },
  },
  methods: {
    close() {
      this.sessionLogDialog = false;
      this.$emit("close");
    },
    getScheduleSessionHistory() {
      this.$http
        .get(
          `venues/trainers/customers/schedule/history?booking_id=${this.bookingId}`
        )
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            this.sessionLogs = data;
            this.sessionLogDialog = true;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    confirmAttendance(data) {
      let msg =
        "This will mark the attendance of the customer. By clicking Yes you can confirm the operation.";
      this.confirmModel = {
        id: data.training_session_id,
        title: `Do you want to mark the attendance?`,
        description: msg,
        type: "attendance",
        data: data,
      };
    },
    confirmRefund(data) {
      let msg =
        "This will refund the scheduled session to customer training package. By clicking Yes you can confirm the operation.";
      this.confirmModel = {
        id: data.training_session_id,
        title: `Do you want to refund the session?`,
        description: msg,
        type: "refund",
        data: data,
      };
    },
    confirmActions(data) {
      if (data.type == "attendance") {
        this.markAttendance(data.data);
      } else if (data.type == "refund") {
        this.refundSession(data.data);
      }
      this.confirmModel.id = null;
    },
    markAttendance(data) {
      this.showLoader("Loading...");
      var formData = new FormData();
      formData.append("training_session_id", data.training_session_id);
      this.$http
        .post(
          `venues/trainers/customers/sessions/attendance/${data.operation}`,
          formData
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.getScheduleSessionHistory();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    refundSession(data) {
      this.showLoader("Loading...");
      var formData = new FormData();
      formData.append("training_session_id", data.training_session_id);
      this.$http
        .post(`venues/trainers/customers/sessions/refund`, formData)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.getScheduleSessionHistory();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>

<style>
</style>