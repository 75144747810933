var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","width":"900","persistent":""},model:{value:(_vm.sessionLogDialog),callback:function ($$v) {_vm.sessionLogDialog=$$v},expression:"sessionLogDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Session History ")]),_c('v-card-text',[_c('v-container',[_c('v-row',{staticStyle:{"margin-top":"-20px"},attrs:{"no-gutters":""}},[_c('table',{staticClass:"history_table"},[_c('thead',[_c('th',[_vm._v("Timestamp")]),_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Start time")]),_c('th',[_vm._v("End Time")]),_c('th',[_vm._v("Sessions Scheduled")]),_c('th',[_vm._v("Earned Revenue")]),_c('th',[_vm._v("Attendance")]),_c('th',[_vm._v("Status")])]),_vm._l((_vm.sessionLogs),function(session){return _c('tr',{key:session.id,staticClass:"text-center"},[_c('td',[_vm._v(_vm._s(_vm._f("timeStamp")(session.created_at)))]),_c('td',[_vm._v(_vm._s(_vm._f("dateformat")(session.date)))]),_c('td',[_vm._v(_vm._s(_vm._f("timeFormat")(session.start_time)))]),_c('td',[_vm._v(_vm._s(_vm._f("timeFormat")(session.end_time)))]),_c('td',[_vm._v(_vm._s(session.scheduled_sessions))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("toCurrency")(session.customer_trainer_revenue))+" "),_c('br'),_vm._v("(Total: "+_vm._s(_vm._f("toCurrency")(session.total_revenue))+") ")]),_c('td',[(session.status_id == 1)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":session.is_present == 0
                          ? 'default'
                          : session.is_present == 1
                          ? 'success'
                          : 'error',"dark":"","small":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(session.is_present == 0 ? "Attendance" : session.is_present == 1 ? "Attended" : "No show")+" ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.confirmAttendance({
                          training_session_id: session.training_session_id,
                          operation: 'present',
                        })}}},[_c('v-list-item-title',[_vm._v("Mark as attended")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.confirmAttendance({
                          training_session_id: session.training_session_id,
                          operation: 'absent',
                        })}}},[_c('v-list-item-title',[_vm._v("Mark no show")])],1)],1)],1):_c('span',[_vm._v("NA")])],1),_c('td',[(session.status_id == 1)?_c('v-btn',{staticClass:"yellow-color",attrs:{"small":"","dark":""},on:{"click":function($event){return _vm.confirmRefund({
                      training_session_id: session.training_session_id,
                      operation: 'refund',
                    })}}},[_vm._v(" Refund Session ")]):_c('span',[_vm._v("Refunded")])],1)])})],2)])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"ma-2 white--text blue-color",attrs:{"color":" darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Close")])],1)],1),_c('confirm-model',_vm._b({on:{"confirm":_vm.confirmActions,"close":function($event){_vm.confirmModel.id = null}}},'confirm-model',_vm.confirmModel,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }